import React, { useEffect, useContext, useState } from 'react';
import { LoadingOutlined, SearchOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import DevelopersTable from '../../components/tables/DevelopersTable';
import { Button, Input } from 'antd';
import Axios from 'axios';
import EditTeamModal from '../../components/modals/EditTeamModal';

//Ima clg dole na osnovu kojeg vidis sve te column keys, a ovde ispod dodajes
//ime tih keyeva koje zelis da ne budu u tabeli jer je tako uvezano

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'updatedAt',
  'email',
  'city',
  'role',
  'status',
  'createdAt',
  'accountnumber',
  'task',
];

const Developers = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  const [showModal, setShowModal] = useState();
  const storage = sessionStorage.getItem('searchDev');
  const [pin, setPin] = useState(storage ? true : false);

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => {
          let devs = res.data.users;
          // .filter((user) => {
          //   return !user.role.includes('admin');
          // });
          setUseri(devs);
        })
        .catch((err) => console.log(err));
    } else if (!storage && pin) {
      setSearch('');
      setPin(false);
    } else if (storage) {
      setSearch(storage);
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const pinKill = () => {
    setSearch('');
    setPin(false);
    sessionStorage.removeItem('searchDev', search);
  };

  const pinSet = () => {
    setPin(true);
    sessionStorage.setItem('searchDev', search);
  };

  let devs;
  // if (users.data.items && users.data.items.length > 0) {
  //   if (currentuser.data.role.includes('admin')) {
  //     devs = users.data.items.filter((user) => {
  //       return !user.role.includes('admin');
  //     });
  //   } else if (currentuser.data.role.includes('lead')) {
  //     devs = users.data.items.filter((user) => {
  //       return user.role.includes('admin');
  //     });
  //   }
  // }
  if (users.data.items && users.data.items.length > 0) {
    devs = users.data.items;
  }
  //ovde ce ici filter ukoliko nisu deo tima onda ne ide u listu
  let columnKeys;
  if (users.data.items && users.data.items.length > 0 && devs[0]) {
    const keys = Object?.keys(devs[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      });
    }
  } else if (devs && devs.length > 0) {
    tableData = devs.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
    tableData = tableData.sort((a, b) =>
      a.firstName.toLowerCase() > b.firstName.toLowerCase()
        ? 1
        : a.firstName.toLowerCase() < b.firstName.toLowerCase()
        ? -1
        : 0,
    );
  }
  return (
    <div className='table'>
      <div className='actions-block'>
        <Input
          bordered={false}
          placeholder='Search'
          prefix={
            <>
              <SearchOutlined className='search-outlined' />{' '}
              {!pin ? (
                <PushpinOutlined onClick={pinSet} className={!search && 'pointer-events'} />
              ) : (
                <PushpinFilled onClick={pinKill} />
              )}
            </>
          }
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type='primary' onClick={() => setShowModal(!showModal)}>
          Novi tim
        </Button>
      </div>
      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && devs && devs.length === 0 && <h1>Nema developera</h1>}
        {!users.isLoading && devs && devs.length > 0 && (
          <DevelopersTable
            data={tableData}
            columnKeys={columnKeys}
            title='Developers'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
      </div>
      <EditTeamModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Developers;
