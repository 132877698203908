import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button, Avatar, Modal, DatePicker, Space, notification } from 'antd';
import { EditOutlined, EyeOutlined, SearchOutlined, EditFilled, EyeFilled, FieldTimeOutlined, CarOutlined, CarFilled } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import EditUserModal from '../modals/EditUserModal';
import { FaEye } from 'react-icons/fa';
import moment from 'moment';
import Axios from 'axios';

const DevelopersTable = ({ data, columnKeys, title, editPath, viewPath }) => {
  let activeDevelopers = data.filter(developer => developer.status === 'ACTIVE')
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  const user = useContext(UserContext);
  const [vacation, setVacation] = useState();
  const [vacationDate, setVacationDate] = useState();
  let searchInput;
  const { RangePicker } = DatePicker;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const post = async (vacationDate) => {
    if (!vacationDate) {
      notification.error({
        message: 'Unesite datum.',
        placement: 'bottomRight',
      });
    } else {
      try {
        await Axios.post(`${SERVER_URL}/dates`, { ...vacationDate });
        notification.success({
          message: 'Unos uspešan',
          placement: 'bottomRight',
        });

        // setState(true);
        // setModal(false);
      } catch (err) {
        notification.error({
          message: err.response && err.message ? err.message : 'Problem sa unosom podataka. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl?.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  let cols = ['Ime', 'Prezime'];

  const columns = columnKeys.map((item, index) => ({
    key: item,
    title: cols[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));
  columns.unshift({
    width: 100,
    title: 'Avatar',
    className: 'avatar-column',
    render: (text, record) => (
      <div style={{ textAlign: 'center' }}>
        <Avatar size={36} src={record && record.avatar ? getUrl(record.avatar.url) : 'Logo'} />
      </div>
    ),
  });

  columns.push({
    title: 'Akcija',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {user.data.role.includes('admin') && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <EditOutlined
              className='icon-unlock'
              title={`Izmeni developera`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            />
            <EditFilled
              className='icon-lock'
              title={`Izmeni developera`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            />
          </div>
        )}

        {viewPath && (
          <>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
              <Link to={viewPath + record._id}>
                <EyeOutlined
                  title={`Vidi developera`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  className='icon-unlock'
                />
                <EyeFilled
                  title={`Vidi developera`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  className='icon-lock'
                />
              </Link>
            </div>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', marginBottom: '8px' }}>
              <Link to={`/view-developer2/${record._id}`}>
                <FaEye title={`Vidi presek developera`} style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
              <Link to={`/admin/vacation/${record._id}`}>
                <CarOutlined
                  title={`Vidi developera`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  className='icon-unlock'
                />
                <CarFilled
                  title={`Vidi developera`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  className='icon-lock'
                />
              </Link>
            </div>
            {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', marginBottom: '5px' }}>
              <FieldTimeOutlined
                onClick={() => {
                  setVacation(!vacation);
                }}
                title={`Godišnji odmor `}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <Modal
                className='deleteModal'
                centered={true}
                visible={vacation}
                // onCancel={() => setDeletePop(!deletePop)}
                // onOk={() => deleteHandler(deleteUser)}
                okText='Unesite vreme'
                cancelText='Odustani'
                onOk={() => {
                  const body = {
                    fromDate: moment(new Date(vacationDate[0])).format('YYYY-MM-DD[T00:00:00.000Z]'),
                    toDate: moment(new Date(vacationDate[1])).format('YYYY-MM-DD[T00:00:00.000Z]'),
                  };

                  post(body);
                }}
              >
                <RangePicker
                  format={'DD.MM.YYYY'}
                  onChange={(value) => {
                    setVacationDate(value);
                  }}
                />
                {promptName && <p>{promptName.toUpperCase()}?</p>}
              </Modal>
            </div> */}
          </>
        )}
      </div>
    ),
  });

  return (
    <div>
      <Table size='middle' bordered dataSource={activeDevelopers} columns={columns} rowKey='_id' pagination={false} />
      <EditUserModal userId={userID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default DevelopersTable;
