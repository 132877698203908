import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import ClientSecondView from '../../components/tables/ClientSecondView';

const ViewClientTable = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [dates, setDates] = useState(undefined);
  const [client, setClient] = useState(undefined);
  useEffect(() => {
    if(id) {
      fetchDatesData(id);
      fetchClientData(id);
    }
    
  }, [id]);

  const fetchClientData = async (id) => {
    try {
      const clientData = await Axios.get(`${SERVER_URL}/clients/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then((res) => {
          setClient(res.data)
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchDatesData = async (id) => {
    try {
      const datesData = await Axios.get(`${SERVER_URL}/dates/client/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then((res) => {
          setDates(res)
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  let clientDates;

  if (dates?.data && dates?.data.length > 0) {
    clientDates = dates.data.filter((date) => date.clientID === id);
    clientDates = clientDates.map((item) => {
    item.date = new Date(item.date);
    return item;
    });
    //developerDates = developerDates.sort((a, b) => (a.date > b.date ? 1 : -1));
  }

  

  return (
    <>
    {
      client && clientDates && (
        <div>
          <ClientSecondView clientDates={clientDates} client={client?.firstName}/>
        </div>
      )
    }
    </>
  );
}

export default ViewClientTable;
