import React, { useContext, useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { ClientsTable } from '../../components/tables';
import Axios from 'axios';
import { notification, Modal, Input, Button, Calendar, Alert } from 'antd';
import { LoadingOutlined, PushpinFilled, PushpinOutlined, SearchOutlined } from '@ant-design/icons';
import TimeModal from '../../components/modals/TimeModal';
import InVoiceModal from '../../components/modals/InVoiceModal';
import moment from 'moment';
import EditClientModal from '../../components/modals/EditClientModal';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

const unwantedColumnKeys = [
  '_id',
  'city',
  'country',
  'system',
  'createdAt',
  'updatedAt',
  '__v',
  'address',
  'email',
  // 'manager',
  'collectibility',
  'managerFactor',
  'invoiceData',
  // 'developers',
  'hourlyRate',
  'notes',
  'avatar',
  'programmingLanguage',
];

const Clients = () => {
  const currentuser = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  // const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');
  const [isLoading, setIsLoading] = useState(true);
  const [clients, fetchClients] = useState();
  const [client, setClient] = useState();
  const [refetcher, setRefetcher] = useState();
  const [input, setInput] = useState();
  const [hoursinput, setHoursinput] = useState(0);
  const [minutesinput, setMinutesinput] = useState(0);
  let [date, setDate] = useState();
  const [search, setSearch] = useState();
  const [clientsfetch, setClientsfetch] = useState();
  const [showModal, setShowModal] = useState();
  const [time, setTime] = useState('');
  const [isTrue, setIsTrue] = useState(true);
  const [estTime, setEstTime] = useState(0);
  const [estHoursinput, setEstHoursinput] = useState(0);
  const [estMinutesinput, setEstMinutesinput] = useState(0);
  const [task, setTask] = useState();
  const storage = sessionStorage.getItem('search');
  const [pin, setPin] = useState(storage ? true : false);
  const today = new Date();

  const handleTime = (value, type) => {
    if (type === 'hours') {
      setTime(+value * 60 + +minutesinput);
      setHoursinput(value);
    } else {
      setTime(hoursinput * 60 + +value);
      setMinutesinput(value);
    }
  };
  const handleEstTime = (value, type) => {
    if (type === 'hours') {
      setEstTime(value * 60 + +estMinutesinput);
      setEstHoursinput(value);
    } else {
      setEstTime(estHoursinput * 60 + +value);
      setEstMinutesinput(value);
    }
  };
  const fetchClientsFunc = async () => {
    // fetchClients(`${SERVER_URL}/clients`, []);
    try {
      setIsLoading(true);
      await Axios.get(`${SERVER_URL}/clients`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        fetchClients(res);
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchClientsFunc();
  }, [refetcher]);

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/clients/search/${search}`).then((res) => {
        setClientsfetch(res.data.clients);
      });
    } else if (!storage && pin) {
      setSearch('');
      setPin(false);
    } else if (storage) {
      setSearch(storage);
    } else {
      setClientsfetch([]);
    }
  }, [search, currentuser]);

  let columnKeys;
  if (clients?.data.items && clients?.data.items.length > 0) {
    // const keys = Object.keys(clients?.data.items[0]);
    // columnKeys = keys.filter((k) => {
    //   return !unwantedColumnKeys.includes(k);
    // });
    // if (!columnKeys.includes('manager')) {
    //   columnKeys.push('manager');
    // } else if (!columnKeys.includes('developers')) {
    //   columnKeys.push('developers');
    // }
    // columnKeys = columnKeys.reverse();
    columnKeys = ['firstName', 'manager', 'developers'];
  }

  // };
  let tableData = [];
  if (search) {
    if (clientsfetch && clientsfetch.length > 0) {
      tableData = clientsfetch.map((item) => {
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        if (item.manager && Array.isArray(item.manager) === true) {
          item.manager = item.manager.slice('').join(', ');
        } else if (!item.manager) {
          item.manager = 'Nema podataka';
        }
        if (item.developers && Array.isArray(item.developers) === true) {
          item.developers = item.developers.slice('').join(', ');
        } else if (!item.developers) {
          item.developers = 'Nema podataka';
        }
        return item;
      });
      tableData = tableData.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
    }
  } else if (clients?.data.items && clients?.data.items.length > 0) {
    if (clients?.data.items[0].manager.includes(`${currentuser.data.firstName} ${currentuser.data.lastName}`)) {
    }
    tableData = clients?.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.manager && Array.isArray(item.manager) === true) {
        item.manager = item.manager.slice('').join(', ');
        // item.managerID = item.manager[1];
      } else if (!item.manager) {
        item.manager = 'Nema podataka';
      }
      if (item.developers && Array.isArray(item.developers) === true) {
        item.developers = item.developers.slice('').join(', ');
      } else if (!item.developers) {
        item.developers = 'Nema podataka';
      }
      return item;
    });
    tableData = tableData.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
  }

  const tableDataActiveClients = tableData.filter((item) => item.status === 'ACTIVE');

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setRefetcher((r) => !r);
      notification.success({
        message: 'Klijent obrisan.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem, molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onEditHandler = async (values) => {
    try {
      await Axios.put(
        `${SERVER_URL}/clients/${values._id}`,
        { ...values },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Podaci su ažurirani.',
        placement: 'bottomRight',
      });
      setRefetcher((r) => !r);
    } catch (err) {
      notification.error({
        message: 'Problem sa ažuriranjem podataka, molimo pokušajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  const clientData = async (id) => {
    const client = clients?.data.items.find((client) => {
      return client._id === id;
    });
    await setClient(client);
  }; //ONOK POZVATI UPDATEUSER ONU FUNCKIJU PROBATI TAKO I KORISTITI ID KOJI DOBIJAS
  if (!date) {
    setDate(moment(today));
  }
  const post = async (dates) => {
    if (!input) {
      notification.error({
        message: 'Unesite opis.',
        placement: 'bottomRight',
      });
    } else if (!task) {
      notification.error({
        message: 'Unesite task koji ste radili.',
        placement: 'bottomRight',
      });
    } else if (!time) {
      notification.error({
        message: 'Unesite utrošeno vreme.',
        placement: 'bottomRight',
      });
    } else if (time === '00:00') {
      notification.error({
        message: 'Unesite utrošeno vreme',
        placement: 'bottomRight',
      });
    } else {
      try {
        await Axios.post(`${SERVER_URL}/dates`, { ...dates });
        notification.success({
          message: 'Unos uspešan',
          placement: 'bottomRight',
        });
        setInput('');
        setModal(false);
        setTime('');
        setHoursinput(0);
        setMinutesinput(0);
        setEstHoursinput(0);
        setEstMinutesinput(0);
        setEstTime(0);
        setTask(undefined);
        // setState(true);
        // setModal(false);
      } catch (err) {
        notification.error({
          message: err.response && err.message ? err.message : 'Problem sa unosom podataka. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const pinKill = () => {
    setSearch('');
    setPin(false);
    sessionStorage.removeItem('search', search);
  };

  const pinSet = () => {
    setPin(true);
    sessionStorage.setItem('search', search);
  };

  return (
    <>
      <div className='table'>
        <div className='actions-block'>
          <Input
            bordered={false}
            placeholder='Search'
            prefix={
              <>
                <SearchOutlined className='search-outlined' />{' '}
                {!pin ? (
                  <PushpinOutlined onClick={pinSet} className={!search && 'pointer-events'} />
                ) : (
                  <PushpinFilled onClick={pinKill} />
                )}
              </>
            }
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('manager')) && (
            <Button type='primary' onClick={() => setShowModal(!showModal)}>
              Novi klijent
            </Button>
          )}
        </div>
        <div style={{ textAlign: 'center', overflowX: 'auto' }}>
          {clients?.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!clients?.isLoading && clients?.data.items && clients?.data.items.length === 0 && <h1>Nema klijenata</h1>}
          {!clients?.isLoading && clients?.data.items && clients?.data.items.length > 0 && (
            <Router>
              <Switch>
                <Route path='/active-clients'>
                  <ClientsTable
                    data={tableDataActiveClients}
                    columnKeys={columnKeys}
                    title='Klijent'
                    editPath='/edit-client/'
                    viewPath='/view-client/'
                    deleteHandler={deleteClientHandler}
                    modal={modal}
                    setModal={setModal}
                    clientData={clientData}
                    invoiceModal={invoiceModal}
                    setInvoiceModal={setInvoiceModal}
                    setIsTrue={setIsTrue}
                    isTrue={isTrue}
                    onEditHandler={onEditHandler}
                  />
                </Route>
                <Route path='/clients'>
                  <ClientsTable
                    data={tableData}
                    columnKeys={columnKeys}
                    title='Klijent'
                    editPath='/edit-client/'
                    viewPath='/view-client/'
                    deleteHandler={deleteClientHandler}
                    modal={modal}
                    setModal={setModal}
                    clientData={clientData}
                    invoiceModal={invoiceModal}
                    setInvoiceModal={setInvoiceModal}
                    setIsTrue={setIsTrue}
                    isTrue={isTrue}
                    onEditHandler={onEditHandler}
                  />
                </Route>
              </Switch>
            </Router>
          )}
        </div>
      </div>
      {client && client.firstName && (
        <Modal
          okText='Da'
          cancelText='Ne'
          className='work-entry-modal'
          title={
            <div>
              <h3 style={{ margin: '0' }}>Unesite vaše sate za: {client.firstName}</h3>
            </div>
          }
          centered
          visible={modal}
          onCancel={() => {
            setInput('');
            setTime('');
            setHoursinput(0);
            setMinutesinput(0);
            setIsTrue(false);
            setMinutesinput(0);
            setEstHoursinput(0);
            setEstTime(0);
            setTask(undefined);
            setModal(false);
            /*setTimeout(() => {
              window.location.reload();
            }, 50);*/
            // reload();
          }}
          onOk={() => {
            setIsTrue(false);
            post({
              date: moment(new Date(date)).format('YYYY-MM-DD[T00:00:00.000Z]'),
              clientID: client._id,
              clientName: client.firstName,
              developerID: currentuser.data.id,
              developerName: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
              description: input,
              task: task,
              time: time,
              estTime: estTime,
            });
            // reload()
            if (modal === false) {
              // setModal(false);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }}
        >
          <TimeModal
            className='work-hours-top'
            client={client}
            setModal={setModal}
            input={input}
            setInput={setInput}
            time={time}
            setTime={setTime}
            hoursinput={hoursinput}
            setHoursinput={setHoursinput}
            minutesinput={minutesinput}
            setMinutesinput={setMinutesinput}
            date={date}
            setDate={setDate}
            handleTime={handleTime}
            isTrue={isTrue}
            setIsTrue={setIsTrue}
            modal={modal}
            estHoursinput={estHoursinput}
            estMinutesinput={estMinutesinput}
            handleEstTime={handleEstTime}
            setTask={setTask}
            task={task}
          />
          <div className='date-picker'>
            <h3>
              <span className='required' style={{ color: '#ca1717', marginRight: '3px' }}>
                *
              </span>
              Datum:
            </h3>
            <Calendar
              open={isTrue}
              allowClear={true}
              fullscreen={false}
              autoFocus={true}
              format={'DD.MM.YYYY'}
              defaultValue={moment()}
              value={date ? moment(date) : undefined}
              onSelect={(value) => {
                setDate(value);
              }}
              onOpenChange={() => {
                setIsTrue(false);
              }}
            />
          </div>
        </Modal>
      )}
      {client && client.firstName && (
        <Modal
          centered
          title={<h3 style={{ height: 10 }}>Podaci o računu</h3>}
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          onOk={() => setInvoiceModal(false)}
          footer={null}
          className='invoice-modal'
        >
          <InVoiceModal client={client} />
        </Modal>
      )}
      <EditClientModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Clients;
