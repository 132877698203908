import React from 'react';

const InVoiceModal = ({ client }) => {
  return (
    <div className='invoice'>
      {client && client.firstName && (
        <div>
          <h4>
            <pre>{client.invoiceData}</pre>
            {client.system && <p>Sistem plaćanja: {client.system}</p>}
          </h4>
        </div>
      )}
    </div>
  );
};

export default InVoiceModal;
