import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { adminRoutes } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';

const Routes = () => {
  const user = useContext(UserContext);
  return (
    <Switch>
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin'
        component={Home}
        allowed={['admin', 'developer', 'manager', 'designer', 'qa']}
      />
      {/* routes from routeConfig file */}
      {adminRoutes.map((route) =>
        route.children.map((item, i) => (
          <ProtectedRoute
            exact
            key={`R_${i}`}
            user={user.data}
            path={item.path}
            component={item.component}
            allowed={item.allowed}
          />
        )),
      )}
      <Route
        exact
        path='/login'
        render={() => (
          <>
            {user.isLoggedIn && user.data?.role.includes('admin') ? <Redirect to='/developers' /> : <Login />}
            {user.isLoggedIn && user.data?.role.includes('developer') ? <Redirect to='/clients' /> : <Login />}
            {user.isLoggedIn && user.data?.role.includes('manager') ? <Redirect to='/clients' /> : <Login />}
            {user.isLoggedIn && user.data?.role.includes('qa') ? <Redirect to='/clients' /> : <Login />}
            {user.isLoggedIn && user.data?.role.includes('designer') ? <Redirect to='/clients' /> : <Login />}
          </>
        )}
      />
      <Route render={() => <Redirect to='/admin' />} />
    </Switch>
  );
};

export default Routes;
