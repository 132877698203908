import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Developers from '../pages/developers/Developers';
import Clients from '../pages/clients/Clients';
import EditClient from '../pages/clients/EditClient';
import ViewClient from '../pages/clients/ViewClient';
import MyAccount from '../pages/users/MyAccount';
import MyEntries from '../pages/developers/MyEntries';
import ViewClientTable from '../pages/clients/ViewClientTable';
import ViewDeveloperTable from '../pages/developers/ViewDeveloperTable';
import Teams from '../components/teams/Teams';
import Vacation from '../pages/users/Vacation';

//visak od ranije
// import Managers from '../pages/developers/Managers';
// import Data from '../pages/data/Data';
// import EditData from '../pages/data/EditData';
// import Categories from '../pages/data/Categories';
// import Attributes from '../pages/data/Attributes';

// import Tag from '../pages/tags/Tag';
// import EditTag from '../pages/tags/EditTag';

// import Pages from '../pages/page/Page';
// import EditPages from '../pages/page/EditPage';
// import { getNodeAtPath } from 'react-sortable-tree';

// const admin = true;
// const manager = true;

export const adminRoutes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:id',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View User',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
      {
        label: 'View Vacation',
        path: '/admin/vacation/:id',
        component: Vacation,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
      {
        label: 'View Account',
        path: '/admin/account/:id',
        component: MyAccount,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
    ],
  },

  {
    label: 'Developeri',
    children: [
      {
        label: 'Lista developera',
        path: '/developers',
        component: Developers,
        allowed: ['admin', 'developer', 'manager', 'designer', 'qa'],
        showInMenu: true,
      },
      {
        label: 'Moji unosi',
        path: '/entries/:id',
        component: MyEntries,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
      {
        label: 'Lista Developera v2',
        path: '/view-developer2/:id',
        component: ViewDeveloperTable,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Klijenti',
    children: [
      {
        label: 'Lista aktivnih klijenata',
        path: '/active-clients',
        component: Clients,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: true,
      },
      {
        label: 'Lista svih klijenata',
        path: '/clients',
        component: Clients,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: true,
      },
      {
        //ovo moze da vidi samo menager ili admin
        label: 'Novi klijent',
        path: '/new-client',
        component: EditClient,
        allowed: ['admin', 'manager'],
        showInMenu: false,
      },
      {
        label: 'Edit Client',
        path: '/edit-client/:id',
        component: EditClient,
        allowed: ['admin', 'manager'],
        showInMenu: false,
      },

      {
        label: 'View Client',
        path: '/view-client/:id',
        component: ViewClient,
        allowed: ['admin', 'manager'],
        showInMenu: false,
      },
      {
        label: 'View Client v2',
        path: '/view-client2/:id',
        component: ViewClientTable,
        allowed: ['admin', 'manager', 'developer', 'qa', 'designer'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Timovi',
  //   children: [
  //     {
  //       label: 'Lista timova',
  //       path: '/teams',
  //       component: Teams,
  //       allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];
export const managerRoutes = [
  // {
  //   label: 'Developeri',
  //   children: [
  //     {
  //       label: 'Lista developera',
  //       path: '/developers',
  //       component: Developers,
  //       allowed: ['manager'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Lista Developera v2',
  //       path: '/view-developer2/:id',
  //       component: ViewDeveloperTable,
  //       allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  {
    label: 'Klijenti',
    children: [
      {
        label: 'Lista klijenata',
        path: '/clients',
        component: Clients,
        allowed: ['manager'],
        showInMenu: true,
      },

      {
        //ovo moze da vidi samo menager ili admin
        label: 'Novi klijent',
        path: '/new-client',
        component: EditClient,
        allowed: ['manager'],
        showInMenu: false,
      },
    ],
  },
];
export const developerRoutes = [
  {
    label: 'Klijenti',
    children: [
      {
        label: 'Lista klijenata',
        path: '/clients',
        component: Clients,
        allowed: ['developer', 'designer', 'qa'],
        showInMenu: true,
      },

      {
        label: 'Lista Developera v2',
        path: '/view-developer2/:id',
        component: ViewDeveloperTable,
        allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
        showInMenu: false,
      },
      {
        label: 'View Client v2',
        path: '/view-client2/:id',
        component: ViewClientTable,
        allowed: ['admin', 'manager', 'developer', 'qa', 'designer'],
        showInMenu: false,
      },
    ],
  },
];

//  {
//         label: 'Moji unosi',
//         path: '/entries/:id',
//         component: MyEntries,
//         allowed: ['admin', 'manager', 'developer', 'designer', 'qa'],
//         showInMenu: true,
//       },
