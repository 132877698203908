import React, { useEffect, useContext } from 'react';
import { Input, Select } from 'antd';
import moment from 'moment';
import InputSpinner from 'react-bootstrap-input-spinner';
import useAxios from '../../hooks/useAxios';

import 'react-datepicker/dist/react-datepicker.css';
import TextArea from 'antd/lib/input/TextArea';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { useState } from 'react';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const TimeModal = ({
  client,
  input,
  setInput,
  time,
  setTime,
  timeinput,
  setTimeinput,
  setHoursinput,
  setMinutesinput,
  date,
  setDate,
  hoursinput,
  minutesinput,
  handleTime,
  isTrue,
  modal,
  setIsTrue,
  estHoursinput,
  estMinutesinput,
  handleEstTime,
  setTask,
  task,
  entries,
  setEstHoursinput,
  setEstTime,
  estTime,
  post,
  modalEntries,
  modalClient,
  setModalClient,
  setClient
}) => {
  const currentuser = useContext(UserContext);
  const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');
  const [clientForm, setClientForm] = useState([]);
  const inputHours = document.querySelector('#hourInput');
  const inputMinutes = document.querySelector('#minutInput');
  const estInputHours = document.querySelector('#estHourInput');
  const estInputMinutes = document.querySelector('#estMinutInput');

  useEffect(() => {
    if (entries || modalEntries) {
      fetchClients(`${SERVER_URL}/clients`, []);
    }
  }, [fetchClients]);

  let clientsData;
  if (clients.data.items && clients.data.items.length > 0) {
    clientsData = clients.data.items.sort((a, b) => {
      return a.firstName > b.firstName ? 1 : -1;
    });
  }

  // const handleWrite = (e) => {
  //   e.preventDefault();
  // };
  // const handleHoursFormat = (e) => {
  //   console.log('e', e.target);
  //   if (e.target?.length === 1) {
  //     inputHours.value = '0' + inputHours.value;
  //   }
  // };
  // const handleMinutesFormat = (value) => {
  //   if (value?.length === 1) {
  //     inputMinutes.value = '0' + inputMinutes.value;
  //   }
  // };
  // const handleEstHoursFormat = () => {
  //   if (estInputHours?.value?.length === 1) {
  //     estInputHours.value = '0' + estInputHours.value;
  //   }
  // };
  // const handleEstMinutesFormat = () => {
  //   if (estInputMinutes?.value?.length === 1) {
  //     estInputMinutes.value = '0' + estInputMinutes.value;
  //   }
  // };

  return (
    <div
      // onClick={() => setIsTrue(true)}

      className='modal modal-popup'
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}
    >
      {/* <div style={{ width: '100%', marginBottom: '20px' }}>
        <h3>Izaberite datum</h3> */}
      {/* <DatePicker
          open={modal ? true : false}
          allowClear={true}
          autoFocus={true}
          format={'DD.MM.YYYY'}
          value={date ? moment(date) : undefined}
          onChange={(value) => {
            setDate(value);
          }}
        /> */}
      {/* </div> */}
      <div style={{ width: '100%', marginBottom: '20px' }}>
        <h3>
          <span className='required'>*</span>Opis:
        </h3>
        <TextArea
          className='work-description'
          placeholder='Opis aktivnosti'
          onChange={(e) => {
            setInput(e.target.value);
          }}
          value={input}
        />
      </div>
      
        <div className='modal-select'>
          <h3>
            <span className='required'>*</span>Izaberi klijenta:
          </h3>
          <Select
            // getPopupContainer={(trigger) => trigger.parentNode}
            showSearch={true}
            value={clientForm[0] || client?.clientName}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            placeholder='Klijent'
            style={{ width: '100%' }}
            onSelect={(value) => {
              if(client === null || client === undefined){
                setClientForm(value)
              }else{
                setClient({clientID: value[1], clientName: value[0]})
              }
                 }}
            onClear={() => {setClient(null) }}
          >
            {clientsData &&
              clientsData.map((client, index) => {
                return (
                  <Select.Option value={[client.firstName, client._id]} key={index}>
                    {client.firstName}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      
      <div className='modal-select'>
        <h3>
          <span className='required'>*</span>Izaberi task:
        </h3>
        <Select
          // getPopupContainer={(trigger) => trigger.parentNode}
          value={task}
          showSearch={true}
          allowClear
          placeholder='Izaberi task'
          style={{ width: '100%', marginBottom: '20px' }}
          onSelect={(value) => setTask(value)}
          onClear={() => setTask()}
        >
          <Select.Option value='development'>Development</Select.Option>
          <Select.Option value='dizajn'>Dizajn</Select.Option>
          <Select.Option value='app development'>App Development</Select.Option>
          <Select.Option value='sastanak'>Sastanak</Select.Option>
          <Select.Option value='testing'>Testing</Select.Option>
          <Select.Option value='manager'>Manager</Select.Option>
          <Select.Option value='marketing'>Marketing</Select.Option>
          <Select.Option value='support'>Support</Select.Option>
          <Select.Option value='akademija'>Akademija</Select.Option>
          <Select.Option value='mentorstvo'>Mentorstvo</Select.Option>
          <Select.Option value='ostalo'>Ostalo</Select.Option>
        </Select>
      </div>
      <div className='time-inputs-group'>
        <div className='time-input-group'>
          <h3>
            {' '}
            <span className='required'>*</span>Unesite utrošeno vreme:
          </h3>
          <div className='time-inputs'>
            <div className='single-time-input'>
              <InputSpinner
                type={'int'}
                arrows={true}
                precision={2}
                max={24}
                min={0}
                step={1}
                value={hoursinput}
                onChange={(value) => {
                  handleTime(value, 'hours');
                }}
                variant={'dark'}
                size='sm'
                // onInput={(e) => handleHoursFormat(this)}
              />
              <span className='time-input-label'>h</span>
            </div>
            <div className='single-time-input'>
              <InputSpinner
                type={'int'}
                arrows={true}
                precision={2}
                max={60}
                min={0}
                step={1}
                value={minutesinput}
                onChange={(value) => {
                  handleTime(value, 'minutes');
                }}
                variant={'dark'}
                size='sm'
                // onInput={(e) => handleMinutesFormat(this)}
              />
              <span className='time-input-label'>min</span>
            </div>
          </div>
          {/* <input
            id='hourInput'
            maxLength={2}
            placeholder='00'
            type='number'
            onInput={(e) => handleHoursFormat(this)}
            value={hoursinput}
            min='0'
            max='24'
            step='1'
            onChange={(e, value) => {
              handleTime(e, 'hours');
            }}
            onKeyPress={(e) => handleWrite(e)}
          />

          <input
            id='minutInput'
            maxLength={2}
            type='number'
            placeholder='00'
            value={minutesinput}
            onInput={(e) => handleMinutesFormat(this)}
            min='0'
            max='60'
            step='1'
            onChange={(e, value) => {
              handleTime(e, 'minutes');
            }}
            onKeyPress={(e) => handleWrite(e)}
          /> */}
        </div>
        <div className='time-input-group'>
          <h3>Unesite estimirano vreme:</h3>
          <div className='time-inputs'>
            <div className='single-time-input'>
              <InputSpinner
                type={'int'}
                arrows={true}
                placeholder='00'
                precision={1}
                max={24}
                min={0}
                step={1}
                value={estHoursinput}
                onChange={(value) => {
                  handleEstTime(value, 'hours');
                }}
                variant={'dark'}
                size='sm'
                // onInput={(e) => handleEstHoursFormat(this)}
              />
              <span className='time-input-label'>h</span>
            </div>
            <div className='single-time-input'>
              <InputSpinner
                type={'int'}
                arrows={true}
                precision={1}
                max={60}
                min={0}
                step={1}
                value={estMinutesinput}
                onChange={(value) => {
                  handleEstTime(value, 'minutes');
                }}
                variant={'dark'}
                size='sm'
                // onInput={(e) => handleEstMinutesFormat(this)}
              />
              <span className='time-input-label'>min</span>
            </div>
          </div>
        </div>
      </div>
      {entries && (
        <div className='my-entries-work-entry-btns'>
          <div>
            <button
              className='ant-btn-primary'
              onClick={() => {
                setIsTrue(false);
                post({
                  date: date
                    ? moment(new Date(date)).format('YYYY-MM-DD[T00:00:00.000Z]')
                    : moment().format('YYYY-MM-DD[T00:00:00.000Z]'),
                  clientID: clientForm && clientForm[1],
                  clientName: clientForm && clientForm[0],
                  developerID: currentuser.data.id,
                  developerName: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
                  description: input,
                  task: task,
                  time: time,
                  estTime: estTime,
                });
                // reload()
                // if (modal === false) {
                //   // setModal(false);
                //   setTimeout(() => {
                //     window.location.reload();
                //   }, 1000);
                // }
              }}
            >
              Unesi
            </button>
            <button
              className='ant-btn-secondary'
              onClick={() => {
                setInput('');
                setTime('');
                setHoursinput(0);
                setMinutesinput(0);
                setIsTrue(false);
                setMinutesinput(0);
                setEstHoursinput(0);
                setEstTime(0);
                setTask(undefined);
                
                /*setTimeout(() => {
                  window.location.reload();
                }, 1000);*/
                // reload();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeModal;
