import React, { useContext, useEffect, useState } from 'react';
import { Table, Input, Button, Modal, notification, Calendar } from 'antd';
import { EditFilled, EditOutlined, SearchOutlined, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { useHistory } from 'react-router';
import TimeModal from '../modals/TimeModal';
import moment from 'moment';

const convertMinsToMins = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return m;
};
const convertMinsToHrs = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h;
};

const HoursTable = ({ data, columnKeys, title, entries, passData, currentuser }) => {
  const user = useContext(UserContext);
  const history = useHistory();

  const [input, setInput] = useState();
  // const [time, setTime] = useState('00:00');
  const [timeinput, setTimeinput] = useState('00:00');
  const [date, setDate] = useState();
  const [hoursinput, setHoursinput] = useState(0);
  const [minutesinput, setMinutesinput] = useState(0);
  const [modal, setModal] = useState(false);
  const [entryData, setEntryData] = useState();
  const [time, setTime] = useState('');
  const [isTrue, setIsTrue] = useState(true);
  const [estTime, setEstTime] = useState(0);
  const [estHoursinput, setEstHoursinput] = useState(0);
  const [estMinutesinput, setEstMinutesinput] = useState(0);
  const [task, setTask] = useState();
  const [deletePop, setDeletePop] = useState();
  const [deleteEntry, setDeleteEntry] = useState();
  const [client, setClient] = useState(null)
  const [modalEntries, setModalEntries] = useState(false);
  const [modalClient, setModalClient] = useState([]);

  let newDate;

  const handleTime = (value, type) => {
    if (type === 'hours') {
      setTime(+value * 60 + +minutesinput);
      setHoursinput(value);
    } else {
      setTime(hoursinput * 60 + +value);
      setMinutesinput(value);
    }
  };
  const handleEstTime = (value, type) => {
    if (type === 'hours') {
      setEstTime(+value * 60 + +estMinutesinput);
      setEstHoursinput(value);
    } else {
      setEstTime(estHoursinput * 60 + +value);
      setEstMinutesinput(value);
    }
  };

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/dates/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Unos je obrisan.',
        placement: 'bottomRight',
      });
      setDeletePop(false)
      /*setTimeout(() => {
        window.location.reload();
      }, 1200);*/
    } catch (error) {
      notification.error({
        message: 'Greska prilikom brisanja unosa. Pokusajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  const editEntry = async (values) => {
    const dateid = entryData._id;

    if (input === '') {
      notification.error({
        message: 'Unesite opis.',
        placement: 'bottomRight',
      });
      return;
    }

    if(client === null){
      notification.error({
        message: 'Unesite klijenta',
        palcement: 'bottomRight'
      });
      return;
    }

    if (!time) {
      notification.error({
        message: 'Unesite utrošeno vreme.',
        placement: 'bottomRight',
      });
      return;
    }
    if (estHoursinput === undefined && estMinutesinput === undefined) {
      notification.error({
        message: 'Unesite estimirano vreme.',
        placement: 'bottomRight',
      });
      return;
    }

    if (!date) {
      notification.error({
        message: 'Unesite datum.',
        placement: 'bottomRight',
      });
      return;
    }

    try {
      await Axios.put(
        `${SERVER_URL}/dates/${dateid}`,
        { ...values },
        { withCredentials: false, headers: { Authorization: `Bearer ${user.data.token}` } },
      );
      await notification.success({
        message: 'Vaš unos je ažuriran.',
        placement: 'bottomRight',
      });
      setInput('');
      setTime('');
      setHoursinput(0);
      setMinutesinput(0);
      setEstHoursinput(0);
      setEstMinutesinput(0);
      setEstTime(0);
      setClient(null)
      setTask(undefined);
      setModalClient(undefined)
      // history.push(`/entries/${user.data.id}`);

      setModal(false);
      /*setTimeout(() => {
        window.location.reload();
      }, 1200);*/
    } catch (err) {
      notification.error({
        message: 'Problem sa ažuriranjem, molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  let cols = ['Datum', 'Klijent', 'Opis', 'Utrošeni sati', 'Estimirani sati'];

  const columns = columnKeys.map((item, index) => ({
    key: item,
    title: cols[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  if (entries) {
    columns.push({
      title: 'Akcija',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <EditOutlined
              className='icon-unlock'
              title={`Izmeni ovaj unos`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => {
                newDate = passData.filter((date) => date._id === record._id);
                setEntryData(newDate[0]);
                setInput(newDate[0].description);
                setTime(newDate[0].time);
                setMinutesinput(convertMinsToMins(newDate[0].time));
                setHoursinput(convertMinsToHrs(newDate[0].time));
                setEstTime(newDate[0].estTime);
                setDate(newDate[0].date);
                setModal(!modal);
                setEstMinutesinput(convertMinsToMins(newDate[0].estTime));
                setEstHoursinput(convertMinsToHrs(newDate[0].estTime));
                setEstTime(newDate[0].estTime);
                setTask(newDate[0].task);
                setModalClient([newDate[0].clientName])
                setClient({clientID: newDate[0].clientID, clientName: newDate[0].clientName})
                setModalEntries(true)
              }}
            />
            <EditFilled
              className='icon-lock'
              title={`Izmeni ovaj unos`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => {
                newDate = passData.filter((date) => date._id === record._id);
                setEntryData(newDate[0]);
                setInput(newDate[0].description);
                setTime(newDate[0].time);
                setMinutesinput(convertMinsToMins(newDate[0].time));
                setHoursinput(convertMinsToHrs(newDate[0].time));
                setEstTime(newDate[0].estTime);
                setDate(newDate[0].date);
                setModal(!modal);
                setEstMinutesinput(convertMinsToMins(newDate[0].estTime));
                setEstHoursinput(convertMinsToHrs(newDate[0].estTime));
                setEstTime(newDate[0].estTime);
                setTask(newDate[0].task);
                setModalClient([newDate[0].clientName])
                setClient({clientID: newDate[0].clientID, clientName: newDate[0].clientName})
                setModalEntries(true)
              }}
            />
          </div>
          <div className='lock'>
            <DeleteOutlined className='icon-unlock' />
            <DeleteFilled
              className='icon-lock'
              onClick={() => {
                setDeletePop(!deletePop);
                setDeleteEntry(record._id);
              }}
            />
          </div>
        </div>
      ),
    });
  }
  console.log('selectedClient: ', client)
  return (
    <div>
      <Table
        className='table month-dev-table'
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 50,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />

      {entryData && (
        <Modal
          okText='Da'
          cancelText='Ne'
          className='work-entry-modal'
          title={
            <div>
              <h3 style={{ margin: '0' }}>
                Unesite vaše sate za: {client?.clientName}
              </h3>
            </div>
          }
          centered
          visible={modal}
          onCancel={() => {
            setModal(false);
            setInput('');
            setTime('');
            setHoursinput(0);
            setMinutesinput(0);
            setTimeinput('00:00');
            setEstMinutesinput(0);
            setEstHoursinput(0);
            setEstTime(0);
            setTask(undefined);
            setClient(null)
            /*setTimeout(() => {
              window.location.reload(false);
            }, 50);*/
            setModalEntries(false)
          }}
          onOk={() => {
            editEntry({
              date: moment(new Date(date)).format('YYYY-MM-DD[T00:00:00.000Z]'),
              clientID: client.clientID,
              clientName: client.clientName,
              developerID: user.data.id,
              developerName: `${user.data.firstName} ${user.data.lastName}`,
              description: input,
              time: time,
              estTime: estTime,
              task: task,
            });
            setModalEntries(false)
            setTimeout(() => {
              window.location.reload(false);
            }, 50);
          }
        }
        >
          <TimeModal
            className='work-hours-top'
            setModal={setModal}
            input={input}
            setInput={setInput}
            time={time}
            setTime={setTime}
            hoursinput={hoursinput}
            setHoursinput={setHoursinput}
            minutesinput={minutesinput}
            setMinutesinput={setMinutesinput}
            timeinput={timeinput}
            setTimeinput={setTimeinput}
            handleTime={handleTime}
            date={date}
            setDate={setDate}
            isTrue={isTrue}
            setIsTrue={setIsTrue}
            modal={modal}
            estHoursinput={estHoursinput}
            estMinutesinput={estMinutesinput}
            handleEstTime={handleEstTime}
            setTask={setTask}
            task={task}
            modalClient={modalClient}
            setModalClient={setModalClient}
            setEstHoursinput={setEstHoursinput}
            setHoursModal={true}
            modalEntries={modalEntries}
            client={client}
            setClient={setClient}
          />
          <div className='date-picker'>
            <h3>
              <span className='required' style={{ color: '#ca1717', marginRight: '3px' }}>
                *
              </span>
              Datum:
            </h3>
            <Calendar
              className='calendar-date-picker'
              open={isTrue}
              allowClear={true}
              fullscreen={false}
              autoFocus={true}
              format={'DD.MM.YYYY'}
              value={date ? moment(date) : undefined}
              onSelect={(value) => {
                setDate(value);
              }}
              onOpenChange={() => {
                setIsTrue(false);
              }}
            />
          </div>
        </Modal>
      )}
      <Modal
        className='deleteModal'
        centered={true}
        visible={deletePop}
        onCancel={() => setDeletePop(!deletePop)}
        onOk={() => deleteHandler(deleteEntry)}
        okText='Da'
        cancelText='Ne'
      >
        <p>Da li ste sigurni da želite da obrišete ovaj unos?</p>
      </Modal>
    </div>
  );
};

export default HoursTable;
