import React, { useContext } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url('/bg-bez-logoa.jpg')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 1)',
  boxShadow: '0 0 5px gray',
};
  
const companyLogoStyle = {
  maxWidth: '250px',
  marginBottom: '50px',
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log' style={backgroundStyle}>
      <div className='card-wrapper'>
        <Card className='login-header' bordered={false}style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/logoD.svg" alt="Universal Logo" />
          <Meta
            title="Prijavi se"
          />
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Unesite email!' }]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Unesite sifru!' }]}>
              <Input.Password placeholder='Šifra' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{'width':'100%'}} htmlType='submit'>
                Prijavi se
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
