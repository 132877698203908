import React, { useEffect, useContext, useState } from 'react';
import { Avatar, Layout, Typography, Card, Select, Button, DatePicker } from 'antd';
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import DevHoursTable from '../../components/tables/DevHoursTable';
import EditUserModal from '../../components/modals/EditUserModal';
import Linkify from 'react-linkify/dist/components/Linkify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const { RangePicker } = DatePicker;
const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'updatedAt',
  'email',
  'city',
  'role',
  'status',
  'createdAt',
  'clientID',
  'developerID',
  'developerName',
  'task',
];

const convertMinsToHrsMins = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h + ':' + m;
};

const { Content, Sider } = Layout;
const { Text } = Typography;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [dates, fetchDates] = useAxios('', [], currentuser.data.token, 'get');
  const [vacations, fetchVacations] = useAxios('', [], currentuser.data.token, 'get');
  const [client, setClient] = useState();
  const [hoursSum, setHoursSum] = useState(0);
  const [filteredDates, setFilteredDates] = useState();
  const [value, setValue] = useState();
  const [csvDate, setCsvDate] = useState('')

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  useEffect(() => {
    fetchDates(`${SERVER_URL}/dates/dev/${id}`, []);
  }, [id, fetchDates]);

  useEffect(() => {
    fetchVacations(`${SERVER_URL}/vacations/${id}`, []);
  }, [id, fetchVacations]);

  let devDates;
  if (dates.data && dates.data.length > 0) {
    devDates = dates.data;
    devDates = devDates.map((item) => {
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);
      item.date = item.date.split('T')[0].split('-').reverse().join('.');
      item.description = item.description;
      return item;
    });
    let dateSorted = devDates.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });
    devDates = dateSorted.sort((a, b) => {
      return a.developerName > b.developerName ? 1 : a.developerName < b.developerName ? -1 : 0;
    });
  }
  let klijenti = new Set();
  let clients = [];
  if (devDates) {
    devDates.map((date) => {
      if (klijenti.has(date.clientID)) {
        return false;
      } else {
        klijenti.add(date.clientID);
        clients.push({ clientName: date.clientName, clientID: date.clientID });
      }
    });
    clients = clients.sort((a, b) => {
      return a.clientName > b.clientName ? 1 : -1;
    });
  }
  const filterHandler = (value, clientId) => {
    let dates;
    let clientsFilter;
    let monthly;
    //clientId je param koji se dobija u funkciju, clientID je param iz baze

    if (clientId && value) {
      clientsFilter = devDates?.filter((client) => client.clientID === clientId);
      dates = clientsFilter?.filter((date) => Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >= Date.parse(moment(value[0]).format('YYYY-MM-DD')) && Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <= Date.parse(moment(value[1]).format('YYYY-MM-DD')));
      setCsvDate(`${value[0].format('DD-MM-YYYY')}_${value[1].format('DD-MM-YYYY')}`)
    } else if (clientId) {
      dates = devDates?.filter((date) => date.clientID === clientId);
    } else if (value) {
      dates = devDates?.filter((date) => Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >= Date.parse(moment(value[0]).format('YYYY-MM-DD')) && Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <= Date.parse(moment(value[1]).format('YYYY-MM-DD')));
      setCsvDate(`${value[0].format('DD-MM-YYYY')}_${value[1].format('DD-MM-YYYY')}`)
    } else if (!clientId && !value) {
      dates = devDates;
      setFilteredDates(dates);
    }
    if (dates) {
      setFilteredDates(dates);
      monthly = dates.map((item) => {
        if (typeof item.time === 'string') item.time = +item.time.split(':').reduce((acc, time) => 60 * acc + +time);
        return item;
      });
      const sumall = monthly.map((item) => item.time).reduce((prev, curr) => prev + curr, 0);
      setHoursSum(convertMinsToHrsMins(sumall));
    }
  };
  const resetHandler = () => {
    setClient(undefined);
    filterHandler(null, null);
    setHoursSum(null);
    setValue(null);
  };
  let columnKeys;
  if (dates.data && dates.data.length > 0) {
    const keys = Object.keys(dates.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('estTime')) {
      columnKeys.push('estTime');
    }
  }

  let tableData = filteredDates ? filteredDates : devDates;
  if (filteredDates && filteredDates.length > 0) {
    let preTableData = filteredDates.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);

      item.date = item.date.split('T')[0].split('-').reverse().join('.');
      return item;
    });
    let dateSorted = preTableData.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });
    tableData = dateSorted;
  }

  let sumAll;
  if (vacations && vacations?.data && vacations?.data?.length > 0) {
    sumAll = vacations?.data?.map((item) => item.year === +moment().format('YYYY') && item.duration).reduce((prev, curr) => prev + curr, 0);
  }

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  const csvDataTable = tableData?.map(td => {
    return(
    {
      developer: td.developerName,
      ime_klijenta: td.clientName, 
      datum: td.date,
      opis: td.description,
      utroseni_sati: td.time,
      estimirani_sati: td.estTime
    })
  })

  return (
    <div className='content-wrapper'>
      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <EditOutlined onClick={() => setShowModal(!showModal)} className='edit-user-profile' />

                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />
              </div>
              <Card bordered={false} className='profile-side-info'>
                <p>
                  <Text strong>Ime: </Text>
                  {user.data ? user.data.firstName : 'No data'}
                </p>
                <p>
                  <Text strong>Prezime: </Text>
                  {user.data ? user.data.lastName : 'No data'}
                </p>
                <p>
                  <Text strong>Email: </Text>
                  {user.data ? user.data.email : 'No data'}
                </p>
                <p>
                  <Text strong>Tekuci racun: </Text>
                  {user.data ? user.data.accountnumber : 'No data'}
                </p>
                <p>
                  <Text strong>Broj telefona: </Text>
                  {user.data ? user.data.phone : 'No data'}
                </p>
                <p style={{ textTransform: 'capitalize' }}>
                  <Text strong>Uloga: </Text>
                  {user.data
                    ? user.data.role.map((role, idx) => {
                      if (idx + 1 === user.data.role.length) {
                        return `${role}`;
                      } else {
                        return `${role},`;
                      }
                    })
                    : 'No data'}
                </p>
                <p>
                  <Text strong>Status: </Text>
                  {user.data ? user.data.status : 'No data'}
                </p>
                <Link to={`/admin/vacation/${id}`}>
                  <p>
                    <Text strong>Godisnji odmor: </Text>
                    {sumAll !== undefined ? sumAll : '0'}
                  </p>
                </Link>
                <div style={{ marginBottom: '20px' }}>
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={client}
                    showSearch={true}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    allowClear
                    placeholder='Klijent'
                    style={{ width: '100%' }}
                    onSelect={(value) => setClient(value)}
                    onClear={() => setClient(undefined)}
                  >
                    {clients &&
                      clients.map((client, index) => {
                        return (
                          <Select.Option value={client.clientID} key={index}>
                            {client.clientName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div>
                  <RangePicker
                    style={{ width: '100%', marginBottom: '20px' }}
                    format='DD.MM.YYYY'
                    value={value}
                    onChange={val => setValue(val)}
                  />
                  <Button type='primary' onClick={() => filterHandler(value, client)} className='izaberi-btn'>
                    Izaberi
                  </Button>
                  <Button type='primary' onClick={resetHandler}>
                    Resetuj
                  </Button>
                </div>
                <div className='hour-sum'>
                  <h3>Ukupno sati: {hoursSum !== 0 && hoursSum}</h3>
                </div>
              </Card>
            </Sider>
            <Content className='viewTable' style={{ padding: '0 15px', minHeight: 280}}>
              {!devDates && <h1 className='no-entries'>Nema unesenih sati</h1>}
              {devDates && devDates.length > 0 && (
                <div>
                  <CSVLink data={[...csvDataTable]} filename={`${user.data ? user.data.firstName + user.data.lastName : 'no-developer'}-${csvDate ? csvDate : 'all_time'}.csv`} >
                      <button className='CSVbutton' style={{ fontSize: '14px', marginBottom: '10px', marginLeft:'auto' }}>
                        Izvezi kao CSV
                      </button>
                    </CSVLink>
                  <DevHoursTable data={tableData} columnKeys={columnKeys} title='Developers' />
                </div>
              )}
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewUser;
