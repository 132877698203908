import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button, Avatar, Modal } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
} from '@ant-design/icons';
import EditUserModal from '../modals/EditUserModal';
import DeleteModal from '../modals/DeleteModal';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deletePop, setDeletePop] = useState();
  // const [users, fetchUsers] = useAxios('', null, user.data.token, 'get');
  //moram koristiti state za izlistavanje imena jer iz nekog razloga vuce samo ime Test Concorda
  const [promptName, setPromptName] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let searchInput;
  React.useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
  });
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl?.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  let cols = ['Ime', 'Prezime', 'Grad', 'Uloga', 'Status', 'Email', 'Tekući račun'];

  const columns = columnKeys.map((item, index) => ({
    key: item,
    title: cols[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns.unshift({
    width: 100,
    title: 'Avatar',
    className: 'avatar-column',
    render: (text, record) => (
      <div style={{ textAlign: 'center' }}>
        <Avatar size={36} src={record && record.avatar ? getUrl(record.avatar.url) : 'Logo'} />
      </div>
    ),
  });

  if (data) {
    columns.push({
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <EditOutlined
              className='icon-unlock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            />
            <EditFilled
              className='icon-lock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            />
          </div>

          {viewPath && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
              <Link to={viewPath + record._id}>
                <EyeOutlined
                  className='icon-unlock'
                  title={`View ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
                <EyeFilled
                  className='icon-lock'
                  title={`View ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )}

          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <DeleteOutlined
              className='icon-unlock'
              title={`Delete ${title.toLowerCase()}`}
              onClick={() => {
                setPromptName(record.firstName);
                setDeleteUser(record._id);
                if (promptName && deleteUser) {
                  setDeletePop(!deletePop);
                }
              }}
            />
            <DeleteFilled
              className='icon-lock'
              title={`Delete ${title.toLowerCase()}`}
              onClick={() => {
                setPromptName(record.firstName);
                setDeleteUser(record._id);
                if (promptName && deleteUser) {
                  setDeletePop(!deletePop);
                }
              }}
            />
          </div>
        </div>
      ),
    });
  }
  if(windowWidth < 920){
    columns.shift()
  }


  return (
    <div>
      <Table size='middle' bordered dataSource={data} columns={columns} rowKey='_id' pagination={false} />
      <EditUserModal userId={userID} showModal={showModal} setShowModal={setShowModal} />
      <DeleteModal
        promptName={promptName}
        deleteHandler={deleteHandler}
        deletePop={deletePop}
        setDeletePop={setDeletePop}
        deleteUser={deleteUser}
      />
    </div>
  );
};

export default UsersTable;
