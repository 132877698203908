import React from 'react';
import { Table } from 'antd';
import { CSVLink } from 'react-csv';
import axios from 'axios';

const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${m}`;
};

const Month = ({ month, client }) => {
  let groupedByClient;

  const columns = [
    { title: 'Developer', dataIndex: 'developerName' },
    { title: 'Sati', dataIndex: 'sati' },
  ];

  let data = [];

  if (client && client.length > 0) {
    groupedByClient = client?.reduce((r, a) => {
      r[a.developerName] = r[a.developerName] || [];
      r[a.developerName].push(a);
      return r;
    }, Object.create(Object.prototype));
    groupedByClient = Object.values(groupedByClient);
  }

  groupedByClient?.map((client) => {
    let totalTime = 0;
    let developer;
    client.map((item) => {
      totalTime += item.time;
      developer = item.developerName;
    });
    data.push({
      developerName: developer,
      sati: convertMinsToHrsMins(totalTime),
    });
  });
  let year = groupedByClient[0][0].date.getFullYear();
  data.sort((a, b) => a.developerName.localeCompare(b.developerName));

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post('https://sheet.best/api/sheets/e4832cdf-dc9e-47e3-b649-dd9ad39e5f59', data)
      .then((res) => console.log(res));
  };
  return (
    <div className='tableContainer' style={{ padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <h1 style={{ paddingRight: '20px', fontWeight: 'bold', margin: '0' }}>{month}</h1>
        <h2 style={{ paddingRight: '20px', fontWeight: '600', margin: '0' }}>
          Ukupno: {convertMinsToHrsMins(client.map((date) => date.time).reduce((prev, next) => prev + next))}{' '}
        </h2>
        <CSVLink data={[...data]} filename={`${client[0].clientName}-${month}-${year}.csv`}>
          <button className='CSVbutton' style={{ fontSize: '14px' }}>
            Izvezi kao CSV
          </button>
        </CSVLink>
        {/* <button onClick={submitHandler}>Google sheets</button> */}
      </div>
      {client && (
        <>
          <Table
            className='table month-dev-table'
            dataSource={data}
            columns={columns}
            size='middle'
            bordered
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
          />
        </>
      )}
    </div>
  );
};

export default Month;
