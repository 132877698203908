import React, { useEffect, useContext, useState } from 'react';
import { Avatar, Layout, Typography, Card, DatePicker, Button, Table, notification, Popconfirm, Modal } from 'antd';
import { LoadingOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditUserModal';
import moment from 'moment';
import Axios from 'axios';

const { RangePicker } = DatePicker;

const { Content, Sider } = Layout;
const { Text } = Typography;

const ViewVacation = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [vacations, fetchVacations] = useAxios('', [], currentuser.data.token, 'get');
  const [value, setValue] = useState();
  const [filterYear, setFilterYear] = useState();
  const [totalVacation, setTotalVacation] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editValue, setEditValue] = useState();
  const [editEntry, setEditEntry] = useState();

  const columns = [
    { title: 'Pocetak godisnjeg', dataIndex: 'startDate' },
    { title: 'Kraj godisnjeg', dataIndex: 'endDate' },
    { title: 'Trajanje godisnjeg', dataIndex: 'duration' },
  ];

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  useEffect(() => {
    fetchVacations(`${SERVER_URL}/vacations/${id}`, []);
  }, [id, fetchVacations]);

  useEffect(() => {
    YearPickerFilter(moment().format("YYYY"))
  }, [vacations]);

  const disabledDate = current => {
    return moment(current).isoWeekday() >= 6;
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    setEditValue(null);
  };

  const handleOk = () => {
    edit();
    setIsModalVisible(!isModalVisible);
    setEditValue(null);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  let countDays;
  if (value && value.length > 0) {
    let start = value[0];
    let end = value[1];
    countDays = 0;
    while (start <= end) {
      if (start.format('E') !== '6' && start.format('E') !== '7') {
        countDays++;
      }
      start = moment(start, 'YYYY-MM-DD').add(1, 'days');
    }
  }

  let countEditDays;
  if (editValue && editValue.length > 0) {
    let start = editValue[0];
    let end = editValue[1];
    countEditDays = 0;
    while (start <= end) {
      if (start.format('E') !== '6' && start.format('E') !== '7') {
        countEditDays++;
      }
      start = moment(start, 'YYYY-MM-DD').add(1, 'days');
    }
  }

  const vacationsData = vacations && vacations.data && vacations.data.length > 0 && vacations.data.map((item) => {
    item.startDate = moment(item.startDate).format('YYYY-MM-DD');
    item.endDate = moment(item.endDate).format('YYYY-MM-DD');
    return item;
  });

  let sumAll;
  let filteredVacations;
  function YearPickerFilter(year) {
    filteredVacations = vacations.data.filter((item) => item.year === +moment(year).format('YYYY'));
    sumAll = filteredVacations.map((item) => item.duration).reduce((prev, curr) => prev + curr, 0);
    setFilterYear(filteredVacations);
    setTotalVacation(sumAll);
  }

  if (filterYear && currentuser.data.role.includes('admin')) {
    columns.push({
      title: 'Akcija',
      render: (text, record) => (
        <div>
          <EditOutlined
            onClick={() => {
              setIsModalVisible(!isModalVisible);
              setEditEntry(record._id);
            }}
            style={{ fontSize: "14px", marginLeft: 12 }}
          />
          <Popconfirm
            placement='left'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title={`Da li ste sigurni da želite da obrišete`}
            onConfirm={() => {
              onDeleteTask(record._id);
            }}
            okText='Da'
            cancelText='Ne'
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "14px", marginLeft: 12 }}
            />
          </Popconfirm>
        </div>
      )
    })
  };

  const onDeleteTask = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/vacations/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification?.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      /*setTimeout(() => {
        window.location.reload();
      }, 1000);*/
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const edit = async () => {
    if (!editValue) {
      notification.error({
        message: 'Izaberite period godisnjeg odmora.',
        placement: 'bottomRight',
      })
      return;
    }
    if (vacationsData) {
      for (const vDate of vacationsData) {
        if (editEntry !== vDate._id) {
          if (editValue[0].format('YYYY-MM-DD') <= vDate.endDate && vDate.startDate <= editValue[1].format('YYYY-MM-DD')) {
            notification.error({
              message: 'Izaberite validan period godisnjeg odmora.',
              placement: 'bottomRight',
            })
            return;
          }
        }
      }
    }
    if (editValue) {
      const year = moment(editValue[0]).format('YYYY');
      try {
        await Axios.put(`${SERVER_URL}/vacations/${editEntry}`, {
          startDate: editValue[0],
          endDate: editValue[1],
          duration: countEditDays,
          year,
        },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        notification.success({
          message: 'Uspešana izmena',
          placement: 'bottomRight',
        });
        /*setTimeout(() => {
          window.location.reload();
        }, 1000);*/
      } catch (err) {
        notification.error({
          message: err.response && err.message ? err.message : 'Problem sa izmenom podataka. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const post = async () => {
    if (!value) {
      notification.error({
        message: 'Izaberite period godisnjeg odmora.',
        placement: 'bottomRight',
      })
      return;
    }
    if (vacationsData) {
      for (const vDate of vacationsData) {
        if (value[0].format('YYYY-MM-DD') <= vDate.endDate && vDate.startDate <= value[1].format('YYYY-MM-DD')) {
          notification.error({
            message: 'Izaberite validan period godisnjeg odmora.',
            placement: 'bottomRight',
          })
          return;
        }
      }
    }
    if (value) {
      const year = moment(value[0]).format('YYYY');
      try {
        await Axios.post(`${SERVER_URL}/vacations`, {
          developerID: id,
          developerName: `${user.data?.firstName} ${user.data?.lastName}`,
          startDate: value[0],
          endDate: value[1],
          duration: countDays,
          year,
        },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
        notification.success({
          message: 'Unos uspešan',
          placement: 'bottomRight',
        });
        /*setTimeout(() => {
          window.location.reload();
        }, 1000);*/
      } catch (err) {
        notification.error({
          message: err.response && err.message ? err.message : 'Problem sa unosom podataka. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <EditOutlined onClick={() => setShowModal(!showModal)} className='edit-user-profile' />
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />
              </div>
              <Card bordered={false} className='profile-side-info'>
                <p>
                  <Text strong>Ime: </Text>
                  {user.data ? user.data.firstName : 'No data'}
                </p>
                <p>
                  <Text strong>Prezime: </Text>
                  {user.data ? user.data.lastName : 'No data'}
                </p>
                <p>
                  <Text strong>Email: </Text>
                  {user.data ? user.data.email : 'No data'}
                </p>
                <p>
                  <Text strong>Broj telefona: </Text>
                  {user.data ? user.data.phone : 'No data'}
                </p>
                <p style={{ textTransform: 'capitalize' }}>
                  <Text strong>Uloga: </Text>
                  {user.data
                    ? user.data.role.map((role, idx) => {
                      if (idx + 1 === user.data.role.length) {
                        return `${role}`;
                      } else {
                        return `${role},`;
                      }
                    })
                    : 'No data'}
                </p>
                <p>
                  <Text strong>Godisnji odmor: </Text>
                  {totalVacation}
                </p>
                {(currentuser.data.role.includes('admin')) && (
                  <div>
                    <p style={{ marginBottom: '2px' }}>
                      <Text strong>Izaberi godisnji odmor</Text>
                    </p>
                    <div>
                      <RangePicker
                        value={value}
                        disabledDate={disabledDate}
                        onChange={val => setValue(val)}
                      />
                      <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <Button
                          onClick={() => post()}
                          type='primary'
                          className='izaberi-btn'
                        >
                          Izaberi
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Card>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, margin: '20px 0px' }}>
              <DatePicker
                defaultValue={moment()}
                onChange={val => YearPickerFilter(val)}
                picker='year'
              />
              <Table
                className='table month-dev-table'
                rowKey={record => record._id}
                columns={columns}
                dataSource={filterYear && filterYear.length > 0 && filterYear}
                bordered={true}
                pagination={false}
              />
              <Modal
                className='work-entry-modal'
                style={{ maxWidth: '350px' }}
                title={
                  <div>
                    <h3 style={{ margin: '0' }}>Izmeni period godisnjeg odmora</h3>
                  </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
              >
                <RangePicker
                  value={editValue}
                  disabledDate={disabledDate}
                  onChange={val => setEditValue(val)}
                />
              </Modal>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewVacation;
