import React, { useContext, useEffect } from 'react';
import ClientForm from '../../components/forms/ClientForm';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';

const EditClientModal = ({ id, showModal, setShowModal, onEditHandler }) => {
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchClient(`${SERVER_URL}/clients/${id}`, []);
    }
  }, [id, fetchClient]);

  const onSubmitHandler = async (values) => {
    try {
      const client = await Axios.post(`${SERVER_URL}/clients`, { ...values });
      history.push('/clients');

      notification.success({
        message: 'Klijent kreiran.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
      if (error.message.includes('403')) {
        notification.error({
          message: 'Klijent već postoji. ',
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem sa kreiranjem klijenta, proverite da li su sva polja popunjena ili pokušajte kasnije. ',
          placement: 'bottomRight',
        });
      }
    }
  };

  return (
    <div>
      <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} />
      <div className={showModal ? 'modal show' : 'modal'}>
        {id && !client.isLoading && !client.isError && client.data && (client.data.email || client.data.firstName) && (
          <ClientForm
            onEdit={onEditHandler}
            data={client.data}
            token={currentuser.data.token}
            SERVER_URL={SERVER_URL}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        {/*{id && !client.isLoading && !client.isError && client.data && client.data.firstName && (*/}
        {/*  <ClientForm onEdit={onEditHandler} data={client.data} token={currentuser.data.token} SERVER_URL={SERVER_URL} />*/}
        {/*)}*/}
        {id && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!id && client && !client.data && (
          <ClientForm
            data={null}
            onSubmit={onSubmitHandler}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  );
};

export default EditClientModal;
