import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Select, Result, Modal, notification } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName, setPromptName] = useState();
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };
  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push('/admin/users');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  const onClickHandler = () => {
    if (isNew) {
      if (!data?.password) {
        return;
      }
    }
     setTimeout(() => {
       window.location.reload();
     }, 1000);
    setShowModal(!modal);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card style={{height: '100vh', overflow: 'scroll'}} title={`${isNew ? 'Novi korisnik' : 'Uredi korisnika'} `} bordered={false}>
            <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
            {currentuser.data.role.includes('admin') && profile && (
              <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
                Delete user <DeleteOutlined />
              </div>
            )}
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item className='upload-wrapper right' name='avatar'>
                  <UploadBox
                    editHandler={avatarHandler}
                    deleteHandler={deleteAvatarHandler}
                    image={image}
                    index={0}
                    name='avatar'
                  />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite email adresu',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite ime!',
                    },
                  ]}
                >
                  <Input placeholder='Ime' />
                </Form.Item>

                <Form.Item
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite prezime!',
                    },
                  ]}
                >
                  <Input placeholder='Prezime' />
                </Form.Item>

                <Form.Item name='phone'>
                  <Input placeholder='Telefon' />
                </Form.Item>

                <Form.Item name='address'>
                  <Input placeholder='Adresa' />
                </Form.Item>

                <Form.Item
                  name='city'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite grad!',
                    },
                  ]}
                >
                  <Input placeholder='Grad' />
                </Form.Item>

                <Form.Item name='zip'>
                  <Input placeholder='PTT' />
                </Form.Item>

                <Form.Item
                  name='country'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite drzavu!',
                    },
                  ]}
                >
                  <Input placeholder='Drzava' />
                </Form.Item>

                {((data && data._id && data._id === currentuser.data.id) || isNew) && (
                  <Form.Item
                    name='password'
                    rules={
                      isNew && [
                        {
                          required: true,
                          message: 'Unesite sifru!',
                        },
                      ]
                    }
                  >
                    <Input.Password placeholder='Sifra' />
                  </Form.Item>
                )}

                {currentuser.data.role.includes('admin') && (
                  <Form.Item
                    name='role'
                    rules={[
                      {
                        required: true,
                        message: 'Izaberite ulogu',
                      },
                    ]}
                  >
                    <Select mode='multiple' placeholder='Uloga' getPopupContainer={(trigger) => trigger.parentNode}>
                      <Select.Option value='admin'>Administrator</Select.Option>
                      <Select.Option value='developer'>Developer</Select.Option>
                      <Select.Option value='manager'>Manager</Select.Option>
                      <Select.Option value='designer'>Designer</Select.Option>
                      <Select.Option value='qa'>QA/Tester</Select.Option>
                      <Select.Option value='lead'>Team Lead</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name='accountnumber'
                  rules={[
                    {
                      message: 'Unesite broj tekuceg racuna',
                    },
                  ]}
                >
                  <Input placeholder='Tekuci racun' />
                </Form.Item>

                {currentuser.data.role.includes('admin') && !isNew && (
                  <Form.Item
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Please select status!',
                      },
                    ]}
                  >
                    <Select getPopupContainer={(trigger) => trigger.parentNode}>
                      <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                      <Select.Option value='DELETED'>DELETED</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                  <div>
                    <Button type='primary' htmlType='submit' style={{ float: 'right' }} onClick={onClickHandler}>
                      {data ? 'Sačuvaj izmene' : 'Dodaj korisnika'}
                    </Button>
                    <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                      Otkaži
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
            {/* {result && (
              <Result
                title='Korisnik je kreitan i verifikacioni email je poslat'
                extra={
                  <Button type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )} */}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Yes'
            cancelText='No'
          >
            <p>Dali ste sigurni da želite da obrišete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
