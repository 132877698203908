import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import sr_Rs from 'antd/lib/locale/sr_RS.js';
import moment from 'moment';
import 'moment/locale/sr';

moment.locale('sr');

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={sr_Rs}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
