import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditUserModal';

//Ima clg dole na osnovu kojeg vidis sve te column keys, a ovde ispod dodajes
//ime tih keyeva koje zelis da ne budu u tabeli jer je tako uvezano

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'updatedAt',
  'createdAt',
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => {
          setUseri(res.data.users);
        })
        .catch((err) => console.log(err));
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem korisnika, molim Vas pokusajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    // columnKeys = ['Ime', 'Prezime', 'Grad', 'Uloga', 'Status', 'Email', 'Kreirano'];
  }

  let tableData = [];
  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      });
      tableData = tableData.sort((a, b) => {
        return a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase() ? 1 : -1;
      });
    }
  } else if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
    tableData = tableData.sort((a, b) => {
      return a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase() ? 1 : -1;
    });
  }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Input
          bordered={false}
          placeholder='Search'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type='primary' onClick={() => setShowModal(!showModal)}>
          Novi korisnik
        </Button>
      </div>

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
      <EditUserModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Users;
