import React, { useContext, useEffect, useState } from 'react';
import { Layout, Typography, Card, Select, Button, Avatar, DatePicker } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ClientHoursTable from '../../components/tables/ClientHoursTable';
import EditUserModal from '../../components/modals/EditUserModal';
import EditClientModal from '../../components/modals/EditClientModal';
import Linkify from 'react-linkify/dist/components/Linkify';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Form } from 'antd';

const { RangePicker } = DatePicker;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'updatedAt',
  'email',
  'city',
  'role',
  'status',
  'createdAt',
  'clientID',
  'developerID',
  'clientName',
  'task',
];

const convertMinsToHrsMins = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h + ':' + m;
};

const { Content, Sider } = Layout;
const { Text } = Typography;

const ViewClient = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [dates, fetchDates] = useAxios('', [], currentuser.data.token, 'get');
  const [client, fetchClient] = useAxios('', [], currentuser.data.token, 'get');
  const [user, fetchUser] = useAxios('', [], currentuser.data.token, 'get');
  const [month, setMonth] = useState();
  const [form] = Form.useForm();
  const [developers, setDevelopers] = useState([]);
  const [hoursSum, setHoursSum] = useState(0);
  const [filteredDates, setFilteredDates] = useState();
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState([]);
  const [csvDate, setCsvDate] = useState('');
  const isNew = client ? false : true;

  useEffect(() => {
    fetchDates(`${SERVER_URL}/dates/client/${id}`);
    fetchClient(`${SERVER_URL}/clients/${id}`, []);
  }, [fetchDates, fetchClient]);

  //ovde pravim listu developera koji su radili na ovom klijentu kako bih ih izlistao u selectu

  // let clientData;
  // if (client.data && client.data.manager) {
  //   clientData = client.data.manager;
  // }
  // useEffect(() => {
  //   if (clientData) {
  //     fetchUser(`${SERVER_URL}/users/${clientData}`, []);
  //   }
  // }, [clientData]);
  let passData;
  let clientDates;
  let copy = JSON.parse(JSON.stringify(dates.data));

  let devs = new Set();
  let developersObj = [];
  if (copy && copy.length > 0) {
    passData = dates.data;
    clientDates = copy;
    clientDates = clientDates.map((item) => {
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);
      item.date = item.date.split('T')[0].split('-').reverse().join('.');
      item.description = <Linkify>{item.description}</Linkify>;
      return item;
    });
    clientDates = clientDates.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });

    clientDates.map((date) => {
      if (devs.has(date.developerID)) {
        return false;
      } else {
        devs.add(date.developerID);
        developersObj.push({ devName: date.developerName, devID: date.developerID });
      }
    });
  }

  let columnKeys;
  if (dates.data && dates.data.length > 0) {
    const keys = Object.keys(dates.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('estTime')) {
      columnKeys.push('estTime');
    }
  }

  const onSubmit = ({ developerIds, val }) => {
    let dates;
    let monthly;

    if (developerIds?.length > 0 && val?.length > 0) {
      let predates = clientDates.filter(
        (date) =>
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >=
            Date.parse(moment(val[0]).format('YYYY-MM-DD')) &&
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <=
            Date.parse(moment(val[1]).format('YYYY-MM-DD')),
      );

      dates = clientDates.filter(
        (cD) =>
          developerIds.includes(cD.developerID) &&
          Date.parse(moment(cD.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >=
            Date.parse(moment(val[0]).format('YYYY-MM-DD')) &&
          Date.parse(moment(cD.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <=
            Date.parse(moment(val[1]).format('YYYY-MM-DD')),
      );
      setCsvDate(`${val[0].format('DD-MM-YYYY')}_${val[1].format('DD-MM-YYYY')}`);
    } else if (developerIds?.length > 0) {
      dates = clientDates.filter((cD) => developerIds.includes(cD.developerID));
    } else if (val?.length > 0) {
      dates = clientDates.filter(
        (cD) =>
          Date.parse(moment(cD.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >=
            Date.parse(moment(val[0]).format('YYYY-MM-DD')) &&
          Date.parse(moment(cD.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <=
            Date.parse(moment(val[1]).format('YYYY-MM-DD')),
      );
      setCsvDate(`${val[0].format('DD-MM-YYYY')}_${val[1].format('DD-MM-YYYY')}`);
    } else if (!developerIds && !month) {
      dates = clientDates;
      setFilteredDates(dates);
    }
    if (dates) {
      setFilteredDates(dates);
      monthly = dates.map((item) => {
        if (typeof item.time === 'string') item.time = +item.time.split(':').reduce((acc, time) => 60 * acc + +time);
        return item;
      });
      const sumall = monthly.map((item) => item.time).reduce((prev, curr) => prev + curr, 0);
      setHoursSum(convertMinsToHrsMins(sumall));
    }
  };
  let tableData = filteredDates ? filteredDates : clientDates;
  if (filteredDates && filteredDates.length > 0) {
    let preTableData = filteredDates.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);
      item.date = item.date.split('T')[0].split('-').reverse().join('.');

      return item;
    });
    tableData = preTableData.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });
  }
  if (tableData && tableData.length > 0) {
  }
  const resetHandler = () => {
    setDevelopers([]);
    setMonth(undefined);
    setHoursSum(null);
    setValue([]);
    setFilteredDates(clientDates);
    setCsvDate(undefined);
  };
  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  const csvDataTable = tableData?.map((td) => ({
    ime_klijenta: td.clientName,
    datum: td.date,
    developer: td.developerName,
    opis: td.description.props.children,
    utroseni_sati: td.time,
    estimirani_sati: td.estTime,
  }));

  return (
    <div>
      <div className='content-wrapper'>
        <div className='content content-full-height'>
          {id && client.isLoading && <LoadingOutlined className='loader' spin />}
          {id && !client.data && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
          {id && client.data && !client.isError && !client.isLoading && (
            <Layout className='site-layout-background'>
              <Sider className='site-layout-background' width={300} theme='light'>
                <div className='logo' style={{ height: 'auto' }}>
                  <EditOutlined onClick={() => setShowModal(!showModal)} className='edit-user-profile' />

                  <Avatar
                    size={100}
                    src={client.data && client.data.avatar ? getUrl(client.data.avatar.url) : 'Logo'}
                  />
                </div>
                <Card bordered={false} className='profile-side-info'>
                  <p className='profile-side-name'>{client.data ? client.data.firstName : 'No data'}</p>
                  <p>
                    <Text strong>Email: </Text>
                    {client.data ? client.data.email : 'No data'}
                  </p>
                  <p>
                    <Text strong>
                      {client.data && client.data.manager && client.data.manager.length <= 1
                        ? `Menadžer:`
                        : `Menadžeri:`}
                    </Text>
                    {client.data && client.data.manager && Array.isArray(client.data.manager)
                      ? client.data.manager.slice('').join(', ')
                      : client.data && client.data.manager && client.data.manager.length <= 1
                      ? client.data.manager
                      : 'No data'}
                  </p>
                  <p style={{ textTransform: 'capitalize' }}>
                    <Text strong>Faktor menadžera: </Text>
                    {client.data ? client.data.managerFactor : 'No data'}
                  </p>
                  <p>
                    <Text strong>Naplativost: </Text>
                    {client.data ? client.data.collectibility : 'No data'}
                  </p>
                  {currentuser.data.role.includes('admin') && (
                    <>
                      <p>
                        <Text strong>Satnica: </Text>
                        {client.data ? client.data.hourlyRate : 'No data'}
                      </p>
                      <p>
                        <Text strong>Napomene: </Text>
                        {client.data ? client.data.notes : 'No data'}
                      </p>
                    </>
                  )}
                  <Form layout='horizontal' form={form} onFinish={(values) => onSubmit(values)} initialValues={client}>
                    <div style={{ marginBottom: '20px' }}>
                      <Form.Item name='developerIds'>
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          //value={developers}
                          showSearch={true}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          placeholder='Developers'
                          style={{ width: '100%' }}
                          /*onSelect={(value) => {
                        setDevelopers(
                          [...developers, value]
                        )}}
                      onClear={() => setDevelopers([])}*/
                          mode='multiple'
                        >
                          {developersObj &&
                            developersObj.length > 0 &&
                            developersObj.map((dev, index) => {
                              return (
                                <Select.Option value={`${dev.devID}`} key={index}>
                                  {dev.devName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name='val'>
                        <RangePicker
                          format='DD.MM.YYYY'
                          style={{ width: '100%', marginBottom: '20px' }}
                          value={value}
                          onChange={(val) => setValue(val)}
                          onClear={() => {
                            setValue([]);
                            setCsvDate(undefined);
                          }}
                        />
                      </Form.Item>
                      {/* <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      value={month}
                      showSearch={true}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      allowClear
                      placeholder='Mesec'
                      style={{ width: '100%', marginBottom: '20px' }}
                      onSelect={(value) => setMonth(value)}
                      onClear={() => setMonth(undefined)}
                    >
                      <Select.Option value='.01.'>Januar</Select.Option>
                      <Select.Option value='.02.'>Februar</Select.Option>
                      <Select.Option value='.03.'>Mart</Select.Option>
                      <Select.Option value='.04.'>April</Select.Option>
                      <Select.Option value='.05.'>Maj</Select.Option>
                      <Select.Option value='.06.'>Jun</Select.Option>
                      <Select.Option value='.07.'>Jul</Select.Option>
                      <Select.Option value='.08.'>Avgust</Select.Option>
                      <Select.Option value='.09.'>Septembar</Select.Option>
                      <Select.Option value='.10.'>Oktobar</Select.Option>
                      <Select.Option value='.11.'>Novembar</Select.Option>
                      <Select.Option value='.12.'>Decembar</Select.Option>
                    </Select> */}
                      <Button htmlType='submit' type='primary' className='izaberi-btn'>
                        Izaberi
                      </Button>
                      <Button type='primary' onClick={resetHandler}>
                        Resetuj
                      </Button>
                    </div>
                  </Form>
                  <div className='hour-sum'>
                    <h3>Ukupno sati: {hoursSum !== 0 && hoursSum}</h3>
                  </div>
                </Card>
              </Sider>
              <Content className='viewTable' style={{ padding: '0 15px', minHeight: '90vh' }}>
                {!clientDates && <h1>Nema unesenih sati</h1>}
                {clientDates && (
                  <div>
                    <CSVLink
                      data={[...csvDataTable]}
                      filename={`${client.data.firstName}-${csvDate ? csvDate : 'all_time'}.csv`}
                    >
                      <button
                        className='CSVbutton'
                        style={{ fontSize: '14px', marginBottom: '10px', marginLeft: 'auto' }}
                      >
                        Izvezi kao CSV
                      </button>
                    </CSVLink>
                    <ClientHoursTable data={tableData} columnKeys={columnKeys} title='Clients' />
                  </div>
                )}
              </Content>
            </Layout>
          )}
        </div>
      </div>
      <EditClientModal id={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewClient;
