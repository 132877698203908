import React from 'react';

const DeleteModal = ({ deletePop, setDeletePop, promptName, deleteHandler, deleteUser }) => {
  return (
    <>
      <div className={deletePop ? 'overlay show' : 'overlay'} onClick={() => setDeletePop(!deletePop)} />
      {deletePop && (
        <div className='deleteModal-container'>
          <div className='deleteModal-body'>
            <p>Da li ste sigurni da želite da obrišete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </div>
          <div className='btns'>
            <button className='da' onClick={() => deleteHandler(deleteUser)}>
              Da
            </button>
            <button className='ne' onClick={() => setDeletePop(!deletePop)}>
              Ne
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteModal;
