import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select } from 'antd';
import { UserContext } from '../../App';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './index';
import { SERVER_URL } from '../../config';
import { CloseOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';

const layout = {
  wrapperCol: { span: 24 },
};

const initialValues = {
  firstName: undefined,
  lead: undefined,
  developers: undefined,
};

const TeamForm = ({ onSubmit, data, onEdit, token, showModal, setShowModal }) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const team = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  useEffect(() => {
    if (currentuser.data.role.includes('admin')) {
      fetchUsers(`${SERVER_URL}/users`, []);
    }
  }, [fetchUsers]);

  let devs;
  if (users.data.items && users.data.items.length > 0) {
    devs = users.data.items.filter((user) => {
      return !user.role.includes('admin');
    });
  }

  let leads;
  if (users.data.items && users.data.items.length > 0) {
    leads = users.data.items.filter((user) => {
      return user.role.includes('lead');
    });
  }

  const onFinishHandler = async (values) => {
    if (isNew) {
      onSubmit(values);
    } else {
      onEdit(values);
    }
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title={isNew ? 'Novi tim' : 'Uredi tim'} bordered={false}>
            <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
            <Form
              layout='horizontal'
              {...layout}
              form={form}
              onFinish={(values) => onFinishHandler(values)}
              initialValues={team}
            >
              <Form.Item name='firstName' required={true}>
                <Input placeholder='Naziv tima' />
              </Form.Item>

              <Form.Item name='lead'>
                <Select
                  allowClear
                  placeholder='Team lead'
                  showSearch={true}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {leads &&
                    leads.length > 0 &&
                    leads.map((manager, idx) => {
                      return (
                        <Select.Option value={[`${manager.firstName} ${manager.lastName}`, manager._id]} key={idx}>
                          {manager.firstName} {manager.lastName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name='developers'>
                <Select
                  allowClear
                  placeholder='Ime i prezime developera'
                  showSearch={true}
                  mode='multiple'
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {devs &&
                    devs.length > 0 &&
                    devs.map((dev, idx) => {
                      return (
                        <Select.Option value={[`${dev.firstName} ${dev.lastName}`, dev._id]} key={idx}>
                          {dev.firstName} {dev.lastName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <div className='team-btns'>
                <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                  {data ? 'Sačuvaj izmene' : 'Kreiraj tim'}
                </Button>
                <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                  Otkaži
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TeamForm;

//Email adresa grad drzava zip invoice
