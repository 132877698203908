import React from 'react';
import { Table } from 'antd';
import { CSVLink } from 'react-csv';
import axios from 'axios';

const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${m}`;
};

const MonthDev = ({ clients, name, developer }) => {
  let groupedByClient;

  const columns = [
    { title: 'Klijent', dataIndex: 'Klijent' },
    { title: 'Naplativost', dataIndex: 'Naplativost' },
    { title: 'Menadžer', dataIndex: 'Menadzer' },
    { title: 'Sati', dataIndex: 'Sati' },
  ];

  let data = [];

  if (clients && clients.length > 0) {
    groupedByClient = clients?.reduce((r, a) => {
      r[a.clientName] = r[a.clientName] || [];
      r[a.clientName].push(a);
      return r;
    }, Object.create(Object.prototype));
    groupedByClient = Object.values(groupedByClient);
  }

  groupedByClient?.map((client) => {
    let totalTime = 0;
    client.map((item) => {
      totalTime += item.time;
    });
    data.push({
      Mesec: name,
      Klijent: client[0].clientName || 'Nema podataka',
      Sati: convertMinsToHrsMins(totalTime),

      Menadzer:
        client[0].manager && client[0].manager.length === 1
          ? client[0].manager
          : client[0].manager && client[0].manager.length > 1
          ? client[0]?.manager?.toString()
          : 'Nema podataka',
      Naplativost: client[0].collectibility,
    });
  });

  /* if (month) {
    const sati = month.map((date) => date.time).reduce((prev, next) => prev + next);
    const conv = convertMinsToHrsMins(sati);
  } */

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  let year = groupedByClient[0][0].date.getFullYear();

  /* Sort by client name */
  if(data.length > 0) {
    data = data.sort((a,b) => a.Klijent.localeCompare(b.Klijent));
  }

  //console.log(name, data?.sort((a,b) => a.Klijent.localeCompare(b.Klijent)));

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post('https://sheet.best/api/sheets/e4832cdf-dc9e-47e3-b649-dd9ad39e5f59', data)
      .then((res) => console.log(res));
  };
  return (
    <div className='tableContainer' style={{ padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <h1 style={{ paddingRight: '20px', fontWeight: 'bold', margin: '0' }}>{name}</h1>
        <h2 style={{ paddingRight: '20px', fontWeight: '600', margin: '0' }}>
          Ukupno: {convertMinsToHrsMins(clients.map((date) => date.time).reduce((prev, next) => prev + next))}{' '}
        </h2>
        <CSVLink data={[...data]} filename={`${developer.firstName} ${developer.lastName}-${name}-${year}.csv`}>
          <button className='CSVbutton' style={{ fontSize: '14px' }}>
            Izvezi kao CSV
          </button>
        </CSVLink>
        {/* <button onClick={submitHandler}>Google sheets</button> */}
      </div>
      {console.log('columns', columns)}
      {clients && (
        <>
          <Table
            className='table month-dev-table'
            dataSource={data}
            columns={columns}
            size='middle'
            bordered
            pagination={{
              defaultPageSize: 20,
              position: 'bottom',
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '50', '100'],
              hideOnSinglePage: true,
            }}
          />
        </>
      )}
    </div>
  );
};

export default MonthDev;
