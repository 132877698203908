import React, { useContext, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import DeveloperSecondView from '../../components/tables/DeveloperSecondView';

const ViewDeveloperTable = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [dates, fetchDates] = useAxios('', [], currentuser.data.token, 'get');
  const [developer, fetchDeveloper] = useAxios('', [], currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');
  useEffect(() => {
    fetchDates(`${SERVER_URL}/dates`);
    fetchDeveloper(`${SERVER_URL}/users/${id}`);
    fetchClients(`${SERVER_URL}/clients`, []);
  }, []);
  let developerDates;

  if (dates.data.items && dates.data.items.length > 0 && clients.data.items && clients.data.items.length > 0) {
    developerDates = dates.data.items.filter((date) => date.developerID === id);
    developerDates = developerDates.map((item) => {
      item.date = new Date(item.date);
      const client = clients.data.items.filter((cl) => {
        return cl._id === item.clientID;
      });
      item.clientName = client[0]?.firstName;
      item.manager = client[0]?.manager ? client[0].manager : 'Unesite podatke';
      item.collectibility = client[0]?.collectibility ? client[0].collectibility : 'Unesite podatke';
      return item;
    });
    //developerDates = developerDates.sort((a, b) => (a.date > b.date ? 1 : -1));
  }

  return (
    <div>
      <DeveloperSecondView developerDates={developerDates} developer={developer.data} />
    </div>
  );
};

export default ViewDeveloperTable;
