import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Content } = Layout;

const AdminLayout = () => {
  const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };
  //
  // const userMenu = (
  //   <Menu style={{ width: 120 }}>
  //     <Menu.Item>
  //       <Link to={`/admin/account/${user.data ? user.data.id : ''}`}>Moj nalog</Link>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <span onClick={handleSignOutSubmit}>Odjavi se</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <NavMenu
        isCollapsed={isCollapsed}
        setCollapsed={setCollapsed}
        collapsedNav={collapsedNav}
        setCollapsedNav={setCollapsedNav}
        user={user}
      />
      <Layout>
        {/* <Header className='admin_header' style={{ color: '#fff', position: 'relative' }}> */}
        <Button type='primary' className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
          {React.createElement(collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>
        <Link to='/admin' className='logo-mobile'>
          <div className='logo'>
            <img src='logoW.svg' alt='Universal logo' />
          </div>
        </Link>
        {/* {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}

        {/* <HomeOutlined /> */}

        {/* <Dropdown overlay={userMenu} trigger={['click', 'hover']}> */}

        <Content className='content-space'>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
