import React, { useEffect, useContext } from 'react';
import { Avatar, Layout, Tabs, Typography, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  return (
    <div className='content-wrapper'>
      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />
              </div>
              <Content style={{ padding: '15px', minHeight: 280 }}>
                <Title style={{ fontSize: '14px' }}> ULOGA</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  {' '}
                  {user.data ? user.data.role[0] : 'Role'}
                </p>
                <Title style={{ fontSize: '14px' }}> EMAIL</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  {' '}
                  {user.data ? user.data.email : 'Email'}
                </p>
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280 }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFIL' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Podaci' bordered={false}>
                      <p>
                        <Text strong>Ime: </Text>
                        {user.data ? user.data.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Prezime: </Text>
                        {user.data ? user.data.lastName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user.data ? user.data.email : 'No data'}
                      </p>
                      <p>
                        <Text strong>Tekući račun: </Text>
                        {user.data ? user.data.accountnumber : 'No data'}
                      </p>

                      <p>
                        <Text strong>Status: </Text>
                        {user.data ? user.data.status : 'No data'}
                      </p>
                    </Card>
                  </div>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewUser;
