import React from 'react';
import Month from '../../pages/clients/Month';

const ClientSecondView = ({ clientDates, client }) => {
  let groupedByYear;
  let groupedByMonth;

  if (clientDates && clientDates.length > 0) {
    groupedByYear = clientDates.reduce((r, a) => {
      r[a.date.getFullYear()] = r[a.date.getFullYear()] || [];
      r[a.date.getFullYear()].push(a);
      return r;
    }, Object.create(Object.prototype));
    groupedByYear = Object.values(groupedByYear);
    groupedByYear = groupedByYear.reverse();

    if (groupedByYear && groupedByYear.length > 0) {
      groupedByMonth = groupedByYear.map((element) => {
        const monthGrouped = element.reduce((r, a) => {
          r[a.date.getMonth()] = r[a.date.getMonth()] || [];
          r[a.date.getMonth()].push(a);
          return r;
        }, Object.create(Object.prototype));
        return monthGrouped;
      });
    }
  }

  return (
    <div className='second-view'>
      <h1 style={{ margin: '10px', fontSize: '2rem', textAlign: 'center', marginTop: '30px' }}>{client}</h1>
      {groupedByMonth &&
        groupedByMonth.map((year, idx) => {
          return (
            <div key={idx}>
              {/* Get the year of the first available month's first entry */}
              <h1 style={{ marginBottom: '0', paddingTop: '20px', paddingLeft: '20px' }}>
                {Object.values(year)[0][0].date.getFullYear()}
              </h1>
              {Object.values(year)
                .reverse()
                .map((element, idx) => {
                  let month;
                  // eslint-disable-next-line no-lone-blocks
                  {
                    /* Logic for writing month instead of number of month that we got from .getMonth() */
                  }
                  switch (element[0].date.getMonth()) {
                    case 0:
                      month = 'Januar';
                      break;
                    case 1:
                      month = 'Februar';
                      break;
                    case 2:
                      month = 'Mart';
                      break;
                    case 3:
                      month = 'April';
                      break;
                    case 4:
                      month = 'Maj';
                      break;
                    case 5:
                      month = 'Jun';
                      break;
                    case 6:
                      month = 'Jul';
                      break;
                    case 7:
                      month = 'Avgust';
                      break;
                    case 8:
                      month = 'Septembar';
                      break;
                    case 9:
                      month = 'Oktobar';
                      break;
                    case 10:
                      month = 'Novembar';
                      break;
                    case 11:
                      month = 'Decembar';
                      break;
                    default:
                      month = 'Greška';
                  }
                  return <Month month={month} client={element} key={idx} />;
                })}
              <hr></hr>
            </div>
          );
        })}
    </div>
  );
};

export default ClientSecondView;
