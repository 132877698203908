import Axios from 'axios';
import React from 'react';
import TeamForm from '../forms/TeamForm';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';

const EditTeamModal = ({ showModal, setShowModal }) => {
  const history = useHistory();

  const onSubmitHandler = async (values) => {
    const { firstName, lead, developers } = values;

    if (!firstName) {
      notification.error({
        message: 'Unesite ime tima. ',
        placement: 'bottomRight',
      });
    }
    if (!lead) {
      notification.error({
        message: 'Unesite team leada. ',
        placement: 'bottomRight',
      });
    }
    if (!developers) {
      notification.error({
        message: 'Unesite developere. ',
        placement: 'bottomRight',
      });
    }
    if (firstName && lead && developers) {
      try {
        const team = await Axios.post(`${SERVER_URL}/teams`, { ...values });
        history.push('/teams');

        notification.success({
          message: 'Tim kreiran.',
          placement: 'bottomRight',
        });
        setShowModal(!showModal);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      } catch (error) {
        console.log(error);
        if (error.message.includes('403')) {
          notification.error({
            message: 'Tim već postoji. ',
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: 'Problem sa kreiranjem tima, proverite da li su sva polja popunjena ili pokušajte kasnije. ',
            placement: 'bottomRight',
          });
        }
      }
    }
  };
  return (
    <>
      <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} />
      <div className={showModal ? 'modal show' : 'modal'}>
        <TeamForm onSubmit={onSubmitHandler} showModal={showModal} setShowModal={setShowModal} />
      </div>
    </>
  );
};

export default EditTeamModal;
