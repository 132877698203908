import React, { useEffect, useContext, useState } from 'react';
import { Layout, Card, Select, Button, Calendar, notification, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import DevHoursTable from '../../components/tables/DevHoursTable';
import Linkify from 'react-linkify/dist/components/Linkify';
import TimeModal from '../../components/modals/TimeModal';
import moment from 'moment';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'updatedAt',
  'email',
  'city',
  'role',
  'status',
  'createdAt',
  'clientID',
  'developerID',
  'developerName',
  'task',
];

const convertMinsToHrsMins = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h + ':' + m;
};

const { Content } = Layout;

const ViewUser = (props, editPath) => {
  const history = useHistory();
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [dates, fetchDates] = useAxios('', [], currentuser.data.token, 'get');
  const [datesData, setDatesData] = useState([]);
  const [client, setClient] = useState();
  const [hoursSum, setHoursSum] = useState(0);
  const [filteredDates, setFilteredDates] = useState();
  const [value, setValue] = useState();

  //prekopirano radi funkcionalnosti iz devhourstable.js
  const [input, setInput] = useState();
  // const [time, setTime] = useState('00:00');
  const [timeinput, setTimeinput] = useState('00:00');
  const [date, setDate] = useState();
  const [hoursinput, setHoursinput] = useState(0);
  const [minutesinput, setMinutesinput] = useState(0);
  const [modal, setModal] = useState(false);
  const [time, setTime] = useState('');
  const [isTrue, setIsTrue] = useState(true);
  const [estTime, setEstTime] = useState(0);
  const [estHoursinput, setEstHoursinput] = useState(0);
  const [estMinutesinput, setEstMinutesinput] = useState(0);
  const [task, setTask] = useState();
  const [entries, setEntries] = useState(true);

  const handleTime = (value, type) => {
    if (type === 'hours') {
      setTime(+value * 60 + +minutesinput);
      setHoursinput(value);
    } else {
      setTime(hoursinput * 60 + +value);
      setMinutesinput(value);
    }
  };
  const handleEstTime = (value, type) => {
    if (type === 'hours') {
      setEstTime(+value * 60 + +estMinutesinput);
      setEstHoursinput(value);
    } else {
      setEstTime(estHoursinput * 60 + +value);
      setEstMinutesinput(value);
    }
  };

  const post = async (dates) => {
    if (!input) {
      notification.error({
        message: 'Unesite opis.',
        placement: 'bottomRight',
      });
    } else if (!task) {
      notification.error({
        message: 'Unesite task koji ste radili.',
        placement: 'bottomRight',
      });
    } else if (!time) {
      notification.error({
        message: 'Unesite utrošeno vreme.',
        placement: 'bottomRight',
      });
    } else if (time === '00:00') {
      notification.error({
        message: 'Unesite utrošeno vreme',
        placement: 'bottomRight',
      });
    } else if (!dates.clientName) {
      notification.error({
        message: 'Izaberite klijenta',
        placement: 'bottomRight',
      });
    } else {
      try {
        await Axios.post(`${SERVER_URL}/dates`, { ...dates });
        notification.success({
          message: 'Unos uspešan',
          placement: 'bottomRight',
        });
        setInput('');
        setModal(false);
        setTime('');
        setHoursinput(0);
        setMinutesinput(0);
        setEstHoursinput(0);
        setEstMinutesinput(0);
        setEstTime(0);
        setTask(undefined);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        // setState(true);
        // setModal(false);
      } catch (err) {
        notification.error({
          message: err.response && err.message ? err.message : 'Problem sa unosom podataka. Molimo pokušajte kasnije.',
          placement: 'bottomRight',
        });
      }
    }
  };

  //ovde je kraj tih info

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  useEffect(() => {
    // fetchDates(`${SERVER_URL}/dates/dev/${id}`, []);

    const fetchDatesData = async () => {
      try {
        const datesResponse = await Axios.get(`${SERVER_URL}/dates/dev/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (datesResponse.status === 403) {
          history.push('/admin');
          return;
        }

        setDatesData(datesResponse.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          history.push('/admin');

          notification.error({
            message: 'Niste autorizovani da biste videli ovu stranicu.',
            placement: 'bottomRight',
          });
        } else {
          console.log('Error:', error);
        }
      }
    };

    fetchDatesData();
  }, []);

  let passData;

  let devDates;
  let copy = JSON.parse(JSON.stringify(datesData));
  if (copy && copy.length > 0) {
    passData = datesData;
    devDates = copy;
    devDates = devDates.map((item) => {
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);
      item.date = item.date.split('T')[0].split('-').reverse().join('.');
      item.description = <Linkify>{item.description}</Linkify>;
      return item;
    });
    devDates = devDates.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });
  }

  const filterHandler = (clientId, value) => {
    let dates;
    let clientsFilter;
    let monthly;
    //clientId je param koji se dobija u funkciju, clientID je param iz baze
    if (clientId && value) {
      clientsFilter = devDates.filter((client) => client.clientID === clientId);
      dates = clientsFilter.filter(
        (date) =>
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >=
            Date.parse(moment(value[0]).format('YYYY-MM-DD')) &&
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <=
            Date.parse(moment(value[1]).format('YYYY-MM-DD')),
      );
    } else if (clientId) {
      dates = devDates.filter((date) => date.clientID === clientId);
    } else if (value) {
      dates = devDates.filter(
        (date) =>
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) >=
            Date.parse(moment(value[0]).format('YYYY-MM-DD')) &&
          Date.parse(moment(date.date, 'DD.MM.YYYY').format('YYYY-MM-DD')) <=
            Date.parse(moment(value[1]).format('YYYY-MM-DD')),
      );
    } else if (!clientId && !value) {
      dates = devDates;
      setFilteredDates(dates);
    }
    if (dates) {
      setFilteredDates(dates);
      monthly = dates.map((item) => {
        if (typeof item.time === 'string') item.time = +item.time.split(':').reduce((acc, time) => 60 * acc + +time);
        return item;
      });
      const sumall = monthly.map((item) => item.time).reduce((prev, curr) => prev + curr, 0);
      setHoursSum(convertMinsToHrsMins(sumall));
    }
  };
  const resetHandler = () => {
    setClient(undefined);
    filterHandler(null, null, null);
    setHoursSum(null);
    setValue(null);
  };

  let columnKeys;
  if (datesData && datesData.length > 0) {
    const keys = Object.keys(datesData[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('estTime')) {
      columnKeys.push('estTime');
    }
  }
  let tableData = filteredDates ? filteredDates : devDates;

  if (filteredDates && filteredDates.length > 0) {
    let preTableData = filteredDates.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (typeof item.time === 'number') item.time = convertMinsToHrsMins(item.time);
      if (typeof item.estTime === 'number') item.estTime = convertMinsToHrsMins(item.estTime);
      item.date = item.date.split('T')[0].split('-').reverse().join('.');

      return item;
    });
    tableData = preTableData.sort((a, b) => {
      a = a.date.split('.').reverse().join('');
      b = b.date.split('.').reverse().join('');
      return a > b ? -1 : a < b ? 1 : 0;
    });
  }

  let klijenti = new Set();
  let clients = [];
  if (devDates) {
    devDates.map((date) => {
      if (klijenti.has(date.clientID)) {
        return false;
      } else {
        klijenti.add(date.clientID);
        clients.push({ clientName: date.clientName, clientID: date.clientID });
      }
    });
    clients = clients.sort((a, b) => {
      return a.clientName > b.clientName ? 1 : -1;
    });
  }

  return (
    <div className='entries-content-wrapper'>
      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <div className='my-entries-upper'>
              <Content className='work-entry-modal time-form my-entries-work-entry'>
                <TimeModal
                  className='work-hours-top'
                  input={input}
                  setInput={setInput}
                  time={time}
                  setTime={setTime}
                  hoursinput={hoursinput}
                  setHoursinput={setHoursinput}
                  minutesinput={minutesinput}
                  setMinutesinput={setMinutesinput}
                  timeinput={timeinput}
                  setTimeinput={setTimeinput}
                  handleTime={handleTime}
                  date={date}
                  setDate={setDate}
                  isTrue={isTrue}
                  setIsTrue={setIsTrue}
                  modal={modal}
                  estHoursinput={estHoursinput}
                  estMinutesinput={estMinutesinput}
                  handleEstTime={handleEstTime}
                  setTask={setTask}
                  task={task}
                  entries={entries}
                  setEstHoursinput={setEstHoursinput}
                  setEstTime={setEstTime}
                  estTime={estTime}
                  post={post}
                />
                <div className='date-picker'>
                  <h3>
                    <span className='required' style={{ color: '#ca1717', marginRight: '3px' }}>
                      *
                    </span>
                    Datum:
                  </h3>
                  <Calendar
                    className='calendar-date-picker'
                    open={isTrue}
                    allowClear={true}
                    fullscreen={false}
                    autoFocus={true}
                    format={'DD.MM.YYYY'}
                    defaultValue={moment()}
                    value={date ? moment(date) : undefined}
                    onSelect={(value) => {
                      setDate(value);
                    }}
                    onOpenChange={() => {
                      setIsTrue(false);
                    }}
                  />
                </div>
              </Content>
              <Content className='site-layout-background' width={300} theme='light'>
                <Card
                  title='Filteri'
                  bordered={false}
                  className='profile-side-info filter-my-entries'
                  style={{ maxWidth: 500 }}
                >
                  <div style={{ marginBottom: '20px' }}>
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      showSearch={true}
                      value={client}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      allowClear
                      placeholder='Klijent'
                      style={{ width: '100%' }}
                      onSelect={(value) => setClient(value)}
                      onClear={() => setClient(undefined)}
                    >
                      {clients &&
                        clients.map((client, index) => {
                          return (
                            <Select.Option value={client.clientID} key={index}>
                              {client.clientName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div>
                    <RangePicker
                      format='DD.MM.YYYY'
                      style={{ width: '100%', marginBottom: '20px' }}
                      value={value}
                      onChange={(val) => setValue(val)}
                    />
                    <Button type='primary' onClick={() => filterHandler(client, value)} className='izaberi-btn'>
                      Izaberi
                    </Button>
                    <Button type='primary' onClick={resetHandler}>
                      Resetuj
                    </Button>
                  </div>
                  <div className='hour-sum'>
                    <h3>Ukupno sati: {hoursSum !== 0 && hoursSum}</h3>
                  </div>
                </Card>
              </Content>
            </div>
            <Content style={{ minHeight: 280 }}>
              {!devDates && <h1 className='no-entries'>Nema unesenih sati</h1>}
              {devDates && devDates.length > 0 && (
                <div>
                  <DevHoursTable
                    data={tableData}
                    columnKeys={columnKeys}
                    entries={true}
                    title='Developers'
                    passData={passData}
                    currentuser={currentuser}
                  />
                </div>
              )}
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewUser;
