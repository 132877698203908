import React, { useContext, useEffect } from 'react';
import ClientForm from '../../components/forms/ClientForm';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';

const EditClient = (props) => {
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      fetchClient(`${SERVER_URL}/clients/${id}`, []);
    }
  }, [id, fetchClient]);

  const onSubmitHandler = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/clients`, { ...values });
      history.push('/clients');

      notification.success({
        message: 'Klijent kreiran.',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error);
      if (error.message.includes('403')) {
        notification.error({
          message: 'Klijent već postoji. ',
          placement: 'bottomRight',
        });
      } else {
        notification.error({
          message: 'Problem sa kreiranjem klijenta, proverite da li su sva polja popunjena ili pokušajte kasnije. ',
          placement: 'bottomRight',
        });
      }
    }
  };

  const onEditHandler = async (values) => {
    //ovde radis edit
    try {
      await Axios.put(
        `${SERVER_URL}/clients/${id}`,
        { ...values },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Podaci su ažurirani.',
        placement: 'bottomRight',
      });
      history.push('/clients');
    } catch (err) {
      notification.error({
        message: 'Problem sa ažuriranjem podataka, molimo pokušajte ponovo.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div>
      {id && !client.isLoading && !client.isError && client.data && (client.data.email || client.data.firstName) && (
        <ClientForm onEdit={onEditHandler} data={client.data} token={currentuser.data.token} SERVER_URL={SERVER_URL} />
      )}
      {/*{id && !client.isLoading && !client.isError && client.data && client.data.firstName && (*/}
      {/*  <ClientForm onEdit={onEditHandler} data={client.data} token={currentuser.data.token} SERVER_URL={SERVER_URL} />*/}
      {/*)}*/}
      {id && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
      {!id && client && !client.data && (
        <ClientForm data={null} onSubmit={onSubmitHandler} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
      )}
    </div>
  );
};

export default EditClient;
