import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select } from 'antd';
import { UserContext } from '../../App';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './index';
import { SERVER_URL } from '../../config';
import { CloseOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';

const layout = {
  wrapperCol: { span: 24 },
};

const initialValues = {
  firstName: undefined,
  email: undefined,
  address: undefined,
  city: undefined,
  country: undefined,
  system: undefined,
};

const ClientForm = ({ onSubmit, data, onEdit, token, showModal, setShowModal }) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let client = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  useEffect(() => {
    if (currentuser.data.role.includes('admin') || currentuser.data.role.includes('manager')) {
      fetchUsers(`${SERVER_URL}/users`, []);
    }
  }, [fetchUsers]);

  let devs;
  if (users.data.items && users.data.items.length > 0) {
    devs = users.data.items.filter((user) => {
      return user;
      // ovo je iskljuceno zbog toga da se dozvoli da i admin bude developer
      // return !user.role.includes('admin');
    });
  }

  let managers;
  if (users.data.items && users.data.items.length > 0) {
    managers = users.data.items.filter((user) => {
      return user.role.includes('manager');
    });
  }
  const onFinishHandler = async (values) => {
    setShowModal(!showModal);
    if (isNew) {
      onSubmit(values);
    } else {
      onEdit(values);
    }
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  // const onClickHandler = () => {
  //   setShowModal(!showModal);
  //   /*setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);*/
  // };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card
            style={{ height: '100vh', overflow: 'scroll' }}
            title={isNew ? 'Novi klijent' : 'Uredi podatke'}
            bordered={false}
          >
            <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
            <Form
              layout='horizontal'
              {...layout}
              form={form}
              onFinish={(values) => onFinishHandler(values)}
              initialValues={client}
            >
              <Form.Item className='upload-wrapper right' hidden={true} name='_id'></Form.Item>
              <Form.Item className='upload-wrapper right' name='avatar'>
                <UploadBox
                  editHandler={avatarHandler}
                  deleteHandler={deleteAvatarHandler}
                  image={image}
                  index={0}
                  name='avatar'
                />
              </Form.Item>
              <Form.Item name='firstName' required={true}>
                <Input placeholder='Naziv klijenta' />
              </Form.Item>
              <Form.Item name='email'>
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item name='manager'>
                <Select
                  allowClear
                  placeholder='Ime i prezime menadzera'
                  showSearch={true}
                  mode='multiple'
                  getPopupContainer={(trigger) => trigger.parentNode}
                  // filterOption={(input, option) => option.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {managers &&
                    managers.length > 0 &&
                    managers.map((manager, idx) => {
                      return (
                        <Select.Option value={`${manager.firstName} ${manager.lastName}`} key={idx}>
                          {manager.firstName} {manager.lastName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name='developers'>
                <Select
                  allowClear
                  placeholder='Ime i prezime developera'
                  showSearch={true}
                  mode='multiple'
                  getPopupContainer={(trigger) => trigger.parentNode}
                  // filterOption={(input, option) => option.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {devs &&
                    devs.length > 0 &&
                    devs.map((dev, idx) => {
                      return (
                        <Select.Option value={`${dev.firstName} ${dev.lastName}`} key={idx}>
                          {dev.firstName} {dev.lastName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name='collectibility'>
                <Select allowClear placeholder='Naplativost' getPopupContainer={(trigger) => trigger.parentNode}>
                  <Select.Option value='0.7'>0.7</Select.Option>
                  <Select.Option value='0.75'>0.75</Select.Option>
                  <Select.Option value='0.8'>0.8</Select.Option>
                  <Select.Option value='0.85'>0.85</Select.Option>
                  <Select.Option value='0.9'>0.9</Select.Option>
                  <Select.Option value='0.95'>0.95</Select.Option>
                  <Select.Option value='1'>1</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name='managerFactor'>
                <Select placeholder='Faktor menadzera' getPopupContainer={(trigger) => trigger.parentNode}>
                  <Select.Option value='0'>0</Select.Option>
                  <Select.Option value='0.5'>0.5</Select.Option>
                  <Select.Option value='1'>1</Select.Option>
                  <Select.Option value='1.5'>1.5</Select.Option>
                  <Select.Option value='2'>2</Select.Option>
                  <Select.Option value='2.5'>2.5</Select.Option>
                  <Select.Option value='3'>3</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name='system'>
                <Select
                  placeholder='Izaberite sistem placanja'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value='ConcordSoft Solutions'>ConcordSoft Solutions</Select.Option>
                  <Select.Option value='ConcordSoft'>ConcordSoft</Select.Option>
                  <Select.Option value='Gravity'>Gravity</Select.Option>
                  <Select.Option value='SteminSoft'>SteminSoft</Select.Option>
                  <Select.Option value='Payoneer'>Payoneer</Select.Option>
                  <Select.Option value='UpWork'>UpWork</Select.Option>
                  <Select.Option value='Click Code'>Click Code</Select.Option>
                  <Select.Option value='Kilobyte'>Kilobyte</Select.Option>
                  <Select.Option value='Codinglab 87'>Codinglab 87</Select.Option>
                  <Select.Option value='Web dev solution'>Web dev solution</Select.Option>
                  <Select.Option value='Socrates'>Socrates</Select.Option>
                  <Select.Option value='Sanja Prijic'>Sanja Prijic</Select.Option>
                  <Select.Option value='PayPal Mladen'>PayPal Mladen</Select.Option>
                  <Select.Option value='Kes'>Keš</Select.Option>
                  <Select.Option value='PayPal Solutions'>PayPal Solutions</Select.Option>
                  <Select.Option value='Vladimir Karadzic'>Vladimir Karadzic</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name='programmingLanguage'>
                <Select
                  allowClear
                  placeholder='Programski jezik/Framework'
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value='php'>PHP</Select.Option>
                  <Select.Option value='wordpress'>Wordpress</Select.Option>
                  <Select.Option value='laravel'>Laravel</Select.Option>
                  <Select.Option value='symphony'>Symphony</Select.Option>
                  <Select.Option value='nodeJS'>NodeJS</Select.Option>
                  <Select.Option value='nextJS'>NextJS</Select.Option>
                  <Select.Option value='reactJS'>ReactJS</Select.Option>
                  <Select.Option value='meteorJS'>MeteorJS</Select.Option>
                  <Select.Option value='reactNative'>React Native</Select.Option>
                  <Select.Option value='typeScript'>TypeScript</Select.Option>
                  <Select.Option value='dotNet'>.NET</Select.Option>
                  <Select.Option value='design'>Design</Select.Option>
                  <Select.Option value='python'>Python</Select.Option>
                  <Select.Option value='spark'>Spark</Select.Option>
                  <Select.Option value='kafka'>Kafka</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='status'
                rules={[
                  {
                    required: true,
                    message: 'Please select status!',
                  },
                ]}
              >
                <Select placeholder='Status'>
                  {['ACTIVE', 'INACTIVE'].map((option, index) => (
                    <Select.Option key={`${option}_${index}`} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* {currentuser.data.role.includes('admin') && (
                <>
                  <Form.Item name='hourlyRate'>
                    <Input placeholder='Satnica' />
                  </Form.Item>
                  <Form.Item name='notes'>
                    <Input.TextArea placeholder='Napomene' rows={3} />
                  </Form.Item>
                </>
              )} */}
              <Form.Item name='invoiceData'>
                <Input.TextArea placeholder='Podaci za fakturu' rows={6} />
              </Form.Item>
              <div>
                <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                  {data ? 'Sačuvaj izmene' : 'Dodaj klijenta'}
                </Button>
                <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                  Otkaži
                </Button>
              </div>
            </Form>
          </Card>
          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientForm;

//Email adresa grad drzava zip invoice
