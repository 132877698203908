import React, { useContext, useState } from 'react';
import { Table, Input, Button, Avatar, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import { FaEye } from 'react-icons/fa';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  DollarCircleOutlined,
  FieldTimeOutlined,
  EditFilled,
  DeleteFilled,
  EyeFilled,
  DollarCircleFilled,
} from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import EditClientModal from '../modals/EditClientModal';
import DeleteModal from '../modals/DeleteModal';

const ClientsTable = ({
  data,
  columnKeys,
  title,
  editPath,
  viewPath,
  deleteHandler,
  modal,
  setModal,
  clientData,
  invoiceModal,
  setInvoiceModal,
  setIsTrue,
  isTrue,
  onEditHandler,
}) => {
  const user = useContext(UserContext);
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deletePop, setDeletePop] = useState();
  const [promptName, setPromptName] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const role = user.data.role;
  let cols = ['Naziv', 'Menadžer', 'Developeri'];
  let searchInput;

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl?.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  const columns = columnKeys.map((item, index) => ({
    key: item,
    title: cols[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns.unshift({
    width: 100,
    title: 'Avatar',
    className: 'avatar-column',
    render: (text, record) => (
      <div style={{ textAlign: 'center' }}>
        <Avatar size={36} src={record && record.avatar ? getUrl(record.avatar.url) : 'Logo'} />
      </div>
    ),
  });
  if (data) {
    columns.push({
      title: 'Akcija',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <FieldTimeOutlined
              title={`Dodaj utrosene sate`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => {
                setModal(!modal);
                setIsTrue(true);
                clientData(record._id);
              }}
            />
          </div>

          {(role.includes('admin') ||
            (role.includes('manager') && record.manager.includes(`${user.data.firstName} ${user.data.lastName}`))) && (
            <>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
                <EditOutlined
                  className='icon-unlock'
                  title={`Edit ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => onEditClick(record._id)}
                />
                <EditFilled
                  className='icon-lock'
                  title={`Edit ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => onEditClick(record._id)}
                />
              </div>

              {viewPath && (
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
                  <a href={viewPath + record._id}>
                    <EyeOutlined
                      className='icon-unlock'
                      title={`View ${title.toLowerCase()}`}
                      style={{ textDecoration: 'none', color: 'black' }}
                    />
                    <EyeFilled
                      className='icon-lock'
                      title={`View ${title.toLowerCase()}`}
                      style={{ textDecoration: 'none', color: 'black' }}
                    />
                  </a>
                </div>
              )}
            </>
          )}
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', marginBottom: '8px' }}>
            <a href={`/view-client2/${record._id}`}>
              <FaEye title={`Vidi presek klijenta`} style={{ textDecoration: 'none', color: 'black' }} />
            </a>
          </div>
          {role.includes('admin') && (
            <>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
                <DollarCircleOutlined
                  className='icon-unlock'
                  title={`Podaci za invoice`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => {
                    setInvoiceModal(true);
                    clientData(record._id);
                  }}
                />
                <DollarCircleFilled
                  className='icon-lock'
                  title={`Podaci za invoice`}
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => {
                    setInvoiceModal(true);
                    clientData(record._id);
                  }}
                />
              </div>

              <div style={{ margin: '2px', padding: '4px' }} className='lock'>
                <DeleteOutlined
                  className='icon-unlock'
                  title={`Delete ${title.toLowerCase()}`}
                  onClick={() => {
                    setPromptName(record.firstName);
                    setDeleteUser(record._id);
                    if (promptName && deleteUser) {
                      setDeletePop(!deletePop);
                    }
                  }}
                />
                <DeleteFilled
                  className='icon-lock'
                  title={`Delete ${title.toLowerCase()}`}
                  onClick={() => {
                    setPromptName(record.firstName);
                    setDeleteUser(record._id);
                    if (promptName && deleteUser) {
                      setDeletePop(!deletePop);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      ),
    });
  }
  if (windowWidth < 920) {
    columns.shift();
  }

  return (
    <div>
      <Table size='middle' bordered dataSource={data} columns={columns} rowKey='_id' pagination={false} />
      <EditClientModal id={userID} showModal={showModal} setShowModal={setShowModal} onEditHandler={onEditHandler} />
      <DeleteModal
        promptName={promptName}
        deleteHandler={deleteHandler}
        deletePop={deletePop}
        setDeletePop={setDeletePop}
        deleteUser={deleteUser}
      />
    </div>
  );
};

export default ClientsTable;
