import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { adminRoutes, managerRoutes, developerRoutes } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
// import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav, setCollapsedNav, user } = props;
  const currentuser = useContext(UserContext);
  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  // const routes = user.data ? user.data.role : 'nema';
  const routes = user.data
    ? user.data.role.includes('admin')
      ? adminRoutes
      : user.data.role.includes('manager')
      ? managerRoutes
      : developerRoutes
    : [];

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 451, flex: 'unset !important' }}
      width={210}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/logoW.svg' style={{ maxHeight: '49px' }} alt='Universal api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {routes.map((route) => (
          <SubMenu
            key={route.label.toLowerCase()}
            title={
              isCollapsed ? (
                <route.icon />
              ) : (
                <span>
                  {/*<route.icon />*/}
                  {route.label}
                </span>
              )
            }
          >
            {route.children
              .filter((item) => item.showInMenu)
              .map((item) => (
                <Menu.Item key={item.path} onClick={() => setCollapsedNav(!collapsedNav)}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
        <SubMenu key='Moji unosi' title={<Link to={`/entries/${user?.data?.id}`}>Moji unosi</Link>} />
        {/* {user?.data?.role?.includes('lead') && (
          <SubMenu key='Moj tim' title={<Link to={'/developers'}>Moj tim</Link>} />
        )} */}
      </Menu>
      <div className='sider-profile-controls'>
        <Link
          to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link>

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
      </div>
    </Sider>
  );
};
export default NavMenu;
