import React from 'react';
import MonthDev from '../../pages/developers/MonthDev';

const DeveloperSecondView = ({ developerDates, developer }) => {
  console.log(developerDates);
  let groupedByYear;
  let groupedByMonth;

  if (developerDates && developerDates.length > 0) {
    groupedByYear = developerDates.reduce((r, a) => {
      r[a.date.getFullYear()] = r[a.date.getFullYear()] || [];
      r[a.date.getFullYear()].push(a);
      return r;
    }, Object.create(Object.prototype));
    groupedByYear = Object.values(groupedByYear);
    groupedByYear = groupedByYear.reverse();

    if (groupedByYear && groupedByYear.length > 0) {
      groupedByMonth = groupedByYear.map((element) => {
        const monthGrouped = element.reduce((r, a) => {
          r[a.date.getMonth()] = r[a.date.getMonth()] || [];
          r[a.date.getMonth()].push(a);
          return r;
        }, Object.create(Object.prototype));
        return monthGrouped;
      });
    }
  }

  console.log('groupedByMonth dev', groupedByMonth);
  console.log('groupedByYear dev', groupedByYear)

  return (
    <div className='second-view-dev'>
      <h1 style={{ margin: '10px', fontSize: '2rem', textAlign: 'center', fontWeight: '700', marginTop: '30px' }}>
        {developer.firstName} {developer.lastName}
      </h1>

      {groupedByMonth ? (
        groupedByMonth.length === 0 && ''
      ) : (
        <h1 style={{ textAlign: 'center', marginTop: '4rem', fontSize: '1.5rem' }}>Nema unosa za ovog developera</h1>
      )}

      {groupedByMonth &&
        groupedByMonth.map((year, idx) => {
          return (
            <div key={idx}>
              {/* Get the year of the first available month's first entry */}
              <h1 style={{marginBottom: '0', paddingTop: '20px', paddingLeft: '20px'}}>{Object.values(year)[0][0].date.getFullYear()}</h1>
              {Object.values(year)
                .reverse()
                .map((element, idx) => {
                  let month;
                  // eslint-disable-next-line no-lone-blocks
                  {
                    /* Logic for writing month instead of number of month that we got from .getMonth() */
                  }
                  switch (element[0].date.getMonth()) {
                    case 0:
                      month = 'Januar';
                      break;
                    case 1:
                      month = 'Februar';
                      break;
                    case 2:
                      month = 'Mart';
                      break;
                    case 3:
                      month = 'April';
                      break;
                    case 4:
                      month = 'Maj';
                      break;
                    case 5:
                      month = 'Jun';
                      break;
                    case 6:
                      month = 'Jul';
                      break;
                    case 7:
                      month = 'Avgust';
                      break;
                    case 8:
                      month = 'Septembar';
                      break;
                    case 9:
                      month = 'Oktobar';
                      break;
                    case 10:
                      month = 'Novembar';
                      break;
                    case 11:
                      month = 'Decembar';
                      break;
                    default:
                      month = 'Greška';
                  }
                  return <MonthDev clients={element} name={month} developer={developer} />;
                })}
              <hr></hr>
            </div>
          );
        })}
    </div>
  );
};

export default DeveloperSecondView;
